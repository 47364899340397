import _ from 'lodash';
import moment from 'moment';
import {
    SET_ORDERS_PAGINATED_LIST,
    SET_ORDER_PAGE_PREDIFIEND_QUERIES,
    SET_ORDER_ACTION_CHECKBOX,
    SET_ORDER_MODEL_FROM_SERVER,
    SET_ORDER_MODEL_BY_FORM,
    SET_REALTION_AND_DATA_TO_MODEL_BY_FORM,
    SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM,
    UPDATE_ORDER_LINE_BY_FORM,
    ADD_ORDER_LINE_TO_MODEL_BY_FORM,
    REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM,
    SET_RELATION_MODEL_FROM_SERVER,
    CREATE_ORDER_PAGE_PREDIFINED_QUERIES,
    NEW_SHIPMENT_ADDED_TO_ORDER_MODEL,
    SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER,
    PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM,
    PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM,
    ORDER_HAS_BEEN_CANCELLED
} from './types';
import Lib from '../lara';
import {api} from '../api';

export const orderLineActionChecked = (value, index) => {
    return {
        type: SET_ORDER_ACTION_CHECKBOX,
        payload: {
            index: index, value: value
        }
    }
}

export const fetchById = (id) => (dispatch) => {
    return api.order.read(id).then(res => {
        const {data} = res.data;
        const {shipping_address, billing_address} = data;
        const {relation} = data;
        if (data.shipping_address_id === 0) {
            const shippingId = Lib._uid();
            data.shipping_address_id = shippingId;
            shipping_address.id = shippingId;
            shipping_address.type = 'Shipping';
            relation.addresses.push(shipping_address);
        }
        if (data.billing_address_id === 0) {
            const billingId = Lib._uid();
            data.billing_address_id = billingId;
            billing_address.id = billingId;
            billing_address.type = 'Billing';
            relation.addresses.push(billing_address);
        }
        dispatch({
            type: SET_ORDER_MODEL_FROM_SERVER,
            payload: data
        });
        dispatch({
            type: SET_RELATION_MODEL_FROM_SERVER,
            payload: relation
        });
        return data;
    })
}

export const createFullShipment = (id) => (dispatch) => {
    return api.order.fullShip(id).then(res => {
        dispatch({
            type: NEW_SHIPMENT_ADDED_TO_ORDER_MODEL,
            payload: {
                type: 'Full',
                data: res.data.data
            }
        });
        return res;
    })
}

export const updateModelInStore = (field, value, subProperty) => {
    return {
        type: SET_ORDER_MODEL_BY_FORM,
        payload: {
            subProperty: subProperty,
            field: field,
            value: value
        }
    }
}

export const relationSelectedForNewOrder = (relation, disable_address_populate = false) => {
    return {
        type: SET_REALTION_AND_DATA_TO_MODEL_BY_FORM,
        payload: {
            relation: relation,
            disableAddressPopulate: disable_address_populate
        }
    }
}

export const updateModelShippingCollectInStore = (field, value) => {
    return {
        type: SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM,
        payload: {
            field, value
        }
    }
}

export const updateOrderModelLineItem = (field, value, index) => {
    return {
        type: UPDATE_ORDER_LINE_BY_FORM,
        payload: {
            field, value, index
        }
    }
}

export const deleteOrderModelLineItem = (index) => {
    return {
        type: REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM,
        payload: index
    }
}

export const addOrderModelLineItem = (line) => {
    return {
        type: ADD_ORDER_LINE_TO_MODEL_BY_FORM,
        payload: line
    }
}

export const updateModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.order.update(model).then(res => {
            dispatch({
                type: SET_ORDER_MODEL_FROM_SERVER,
                payload: res.data.data
            });
            return res.data.data;
        });
    } else {
        return api.order.create(model).then(res => {
            dispatch({
                type: SET_ORDER_MODEL_FROM_SERVER,
                payload: res.data.data
            });
            return res.data.data;
        });
    }
}

export const createNewModelInStore = (type) => {
    return {
        type: SET_ORDER_MODEL_FROM_SERVER,
        payload: Lib.models.order(type)
    }
}

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.order.paginated(path).then(res => {
        const {data} = res.data;
        _.each(data.records, r => {
            r.action = false;
        });
        dispatch({
            type: SET_ORDERS_PAGINATED_LIST,
            payload: data
        });
        return res;
    });
}

export const fetchPredefinedRoutes = (page_type = 'Order', version = 0) => (dispatch) => {
    return api.preferences.getPaginationPreference('Order', version, page_type).then(res => {
        const data = res.data.data;
        const payload = [];
        payload.unshift({
            key: 'default',
            value: 'default',
            text: 'Default Order List'
        });
        _.each(data, q => {
            payload.push({
                key: q.id,
                value: _.toString(q.id),
                text: q.name,
                query: q.value
            });
        });
        dispatch({
            type: SET_ORDER_PAGE_PREDIFIEND_QUERIES,
            payload: payload
        })
    });
}

//Type = Cancel || CancelBO
export const orderCancelled = (type, newStatus) => {
    return {
        type: ORDER_HAS_BEEN_CANCELLED,
        payload: null
    }
}

export const saveOrderQuery = (name, query) => (dispatch) => {
    return api.preferences.createPaginationPreference({
        name: name,
        param: query,
        page: 'Order'
    }).then(res => {
        dispatch({
            type: CREATE_ORDER_PAGE_PREDIFINED_QUERIES,
            payload: res.data.data
        });
        return res;
    }).catch(err => {
        throw err;
    })
}

export const setPartialShipmentModelFromOrder = (currentStatus, orderModel) => {
    let model = {
        _id: null,
        shipping_addr: {},
        billing_addr: {},
        courier: {},
        lines: []
    };
    if (!currentStatus) {
        const lines = [];
        _.each(orderModel.lines, l => {
            if (!l.line_status) {
                lines.push({
                    order_line_id: l.id,
                    quantity: l.line_status ? l.line_status.leftover : l.quantity,
                    remaining: l.line_status ? l.line_status.leftover : l.quantity,
                    meta: l
                })
            } else {
                if (l.line_status.leftover > 0) {
                    lines.push({
                        order_line_id: l.id,
                        quantity: l.line_status ? l.line_status.leftover : l.quantity,
                        remaining: l.line_status ? l.line_status.leftover : l.quantity,
                        meta: l
                    })
                }
            }
        });

        model = {
            _id: _.uniqueId(),
            ship_date: moment().format('YYYY-MM-DD').toString(),
            cancel_date: moment().add(1,'day').format('YYYY-MM-DD').toString(),
            shipping_addr: orderModel.shipping_address,
            billing_addr: orderModel.billing_address,
            courier: {
                id: orderModel.shipping.courier_id,
                payment_method: orderModel.shipping.payment,
                account: orderModel.shipping.collect_account.account,
                billing_country_code: orderModel.shipping.collect_account.collect_country_code,
                billing_zip: orderModel.shipping.collect_account.collect_zip_code
            },
            lines:lines
        }
    }
    return {
        type: SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER,
        payload: model
    }
}

export const partialShipmentModelUpdatedByForm = (propertyName, field, value) => {
    return {
        type: PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM,
        payload: {
            propertyName,field,value
        }
    }
}

export const partialShipmentLinesUpdatedByForm = (index, quantity) => {
    return {
        type: PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM,
        payload: {
            index, quantity
        }
    }
}

export const createPartialOnServer = (partialModel, orderId) => (dispatch)=> {
    return api.order.partialShip(orderId, partialModel).then(res => {
        return res.data.data;
    })
}
