//System Reducer
export const GUEST_BOOT_LOADED = "GUEST_BOOT_LOADED";
export const NEW_CLIENT_CREATED_TO_SELECTOR = "NEW_CLIENT_CREATED_TO_SELECTOR";
export const SOME_ACTION = "SOME_ACTION";
export const USER_LOGGED_OUT = "USER_LOGGED_OUT";
export const USER_LOGGED_IN_VIA_LOCAL_STATE = "USER_LOGGED_IN_VIA_LOCAL_STATE";
export const INITIAL_BOOT_LOADED = "INITIAL_BOOT_LOADED";
export const WORKING_CLIENT_SWITCHED = "WORKING_CLIENT_SWITCHED";
export const PREFERENCE_UPDATED_BY_FORM = "PREFERENCE_UPDATED_BY_FORM";
export const UPDATE_PREFERENCE_ON_SERVER = "UPDATE_PREFERENCE_ON_SERVER";
export const SHIP_STATION_WAREHOUSE_LOADED = "SHIP_STATION_WAREHOUSE_LOADED";
export const COURIER_UPDATED_ON_SERVER = "COURIER_UPDATED_ON_SERVER";
export const COURIER_CREATED_ON_SERVER = "COURIER_CREATED_ON_SERVER";
export const CONFIGURATION_RESOURCE_CREATED = "CONFIGURATION_RESOURCE_CREATED";
export const CONFIGURATION_RESOURCE_UDPATED = "CONFIGURATION_RESOURCE_UDPATED";
export const CONFIGURATION_RESOURCE_DELETED = "CONFIGURATION_RESOURCE_DELETED";
export const SHIP_STATION_SHIPPING_LOADED = "SHIP_STATION_SHIPPING_LOADED";
export const SHIPPO_OBJECTS_LOADED = "SHIPPO_OBJECTS_LOADED";
export const MY_PROFILE_UPDATED = "MY_PROFILE_UPDATED";
export const API_TOKEN_REQUESTED = "API_TOKEN_REQUESTED";

//Config Reducer
export const TOGGLE_SHOW_SIDE_BAR = "TOGGLE_SHOW_SIDE_BAR";
export const SET_SUBMENU_TO_SHOW = "SET_SUBMENU_TO_SHOW";
export const GLOBAL_LOADING_START = "GLOBAL_LOADING_START";
export const GLOBAL_LOADING_DONE = "GLOBAL_LOADING_DONE";

//Relation Reducer
export const SET_RELATION_PAGINATED_LIST = "SET_RELATION_PAGINATED_LIST";
export const SET_RELATION_MODEL_FROM_SERVER = "SET_RELATION_MODEL_FROM_SERVER";
export const RELATION_UPDATED_BY_FORM = "RELATION_UPDATED_BY_FORM";
export const UPDATE_RELATION_ON_SERVER = "UPDATE_RELATION_ON_SERVER";
export const ADD_ADDRESS_TO_RELATION = "ADD_ADDRESS_TO_RELATION";
export const UPDATE_ADDRESS_TO_RELATION = "UPDATE_ADDRESS_TO_RELATION";
export const DELETE_ADDRESS_TO_RELATION = "DELETE_ADDRESS_TO_RELATION";

//Inventory Reducer
export const SET_INVENTORIES_PAGINATED_LIST = "SET_INVENTORIES_PAGINATED_LIST";
export const INVENTORY_UPDATED_BY_FORM = "INVENTORY_UPDATED_BY_FORM";
export const SET_INVENTORY_PAGE_PREDIFIEND_QUERIES = "SET_INVENTORY_PAGE_PREDIFIEND_QUERIES";
export const CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES = "CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES";
export const DELETE_INVENTORY_PAGE_PREDIFIEND_QUERIES = "DELETE_INVENTORY_PAGE_PREDIFIEND_QUERIES";
export const SET_INVENTORY_MODEL_FROM_SERVER = "SET_INVENTORY_MODEL_FROM_SERVER";
export const ADD_CATEGORY_TO_INVENTORY = "ADD_CATEGORY_TO_INVENTORY";
export const DELETE_CATEGORY_TO_INVENTORY = "DELETE_CATEGORY_TO_INVENTORY";
export const ADD_UOM_TO_INVENTORY = "ADD_UOM_TO_INVENTORY";
export const UPDATE_UOM_TO_INVENTORY = "UPDATE_UOM_TO_INVENTORY";
export const DELETE_UOM_TO_INVENTORY = "DELETE_UOM_TO_INVENTORY";
export const STORE_ITEM_TO_KIT_INVENTORY = "STORE_ITEM_TO_KIT_INVENTORY";
export const DELETE_ITEM_TO_KIT_INVENTORY = "DELETE_ITEM_TO_KIT_INVENTORY";
export const UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE = "UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE";

//Order Reducer
export const SET_ORDERS_PAGINATED_LIST = "SET_ORDERS_PAGINATED_LIST";
export const SET_ORDER_PAGE_PREDIFIEND_QUERIES = "SET_ORDER_PAGE_PREDIFIEND_QUERIES";
export const CREATE_ORDER_PAGE_PREDIFINED_QUERIES = "CREATE_ORDER_PAGE_PREDIFINED_QUERIES";
export const DELETE_ORDER_PAGE_PREDIFIEND_QUERIES = "DELETE_ORDER_PAGE_PREDIFIEND_QUERIES";
export const SET_ORDER_ACTION_CHECKBOX = "SET_ORDER_ACTION_CHECKBOX";
export const SET_ORDER_MODEL_BY_FORM = "SET_ORDER_MODEL_BY_FORM";
export const SET_ORDER_MODEL_FROM_SERVER = "SET_ORDER_MODEL_FROM_SERVER";
export const SET_REALTION_AND_DATA_TO_MODEL_BY_FORM = "SET_REALTION_AND_DATA_TO_MODEL_BY_FORM";
export const SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM = "SET_SHIPPING_COLLECT_ACCT_TO_MODEL_BY_FORM";
export const UPDATE_ORDER_LINE_BY_FORM = "UPDATE_ORDER_LINE_BY_FORM";
export const ADD_ORDER_LINE_TO_MODEL_BY_FORM = "ADD_ORDER_LINE_TO_MODEL_BY_FORM";
export const REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM = "REMOVE_ORDER_LINE_FROM_MODEL_BY_FORM";
export const ADD_CATEGORY_TO_ORDER_MODEL = "ADD_CATEGORY_TO_ORDER_MODEL";
export const DELETE_CATEGORY_TO_OPRDER_MODEL = "DELETE_CATEGORY_TO_ORDER_MODEL";
export const NEW_SHIPMENT_ADDED_TO_ORDER_MODEL = "NEW_SHIPMENT_ADDED_TO_ORDER_MODEL";
export const ORDER_HAS_BEEN_CANCELLED = "ORDER_HAS_BEEN_CANCELLED";

//Partial Shipment Reducer
export const SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER = "SET_PARTIAL_SHIPMENT_MODEL_FROM_ORDER";
export const PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM = "PARTIAL_SHIPMENT_MODEL_UPDATED_BY_FORM";
export const PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM = "PARTIAL_SHIPMENT_LINES_UPDATED_BY_FORM";

//User & Role Reducer
export const SET_ROLE_LIST = "SET_ROLE_LIST";
export const SET_ROLE_MODEL = "SET_ROLE_MODEL";
export const EXISTING_ROLE_UPDATED = "EXISTING_ROLE_UPDATED";
export const NEW_ROLE_CREATED = "NEW_ROLE_CREATED";
export const SET_USER_PAGINATED_LIST = "SET_USER_PAGINATED_LIST";
export const SET_USER_MODEL_FROM_SERVER = "SET_USER_MODEL_FROM_SERVER";
export const USER_MODEL_UPDATED_BY_FORM = "USER_MODEL_UPDATED_BY_FORM";
export const USER_UPDATED_BY_FORM = "USER_UPDATED_BY_FORM";

//Client Reducer
export const SET_CLIENT_PAGINATED_LIST = "SET_CLIENT_PAGINATED_LIST";
export const SET_CLIENT_MODEL_FROM_SERVER = "SET_CLIENT_MODEL_FROM_SERVER";
export const CLIENT_MODEL_UPDATED_BY_FORM = "CLIENT_MODEL_UPDATED_BY_FORM";
export const CLIENT_CATEGORY_DELETED = "CLIENT_CATEGORY_DELETED";
export const ADD_EMAIL_TO_CLIENT_DETAIL = "ADD_EMAIL_TO_CLIENT_DETAIL";
export const REMOVE_EMAIL_FROM_CLIENT_DETAIL = "REMOVE_EMAIL_FROM_CLIENT_DETAIL";
export const WEBHOOK_ADDED_TO_CLIENT = "WEBHOOK_ADDED_TO_CLIENT";
export const WEBHOOK_MODIFIED_OF_CLIENT = "WEBHOOK_MODIFIED_OF_CLIENT";
export const WEBHOOK_DELETED_FROM_CLIENT = "WEBHOOK_DELETED_FROM_CLIENT";

//Billing Items
export const SET_BILLING_ITEM_PAGINATED_LIST = "SET_BILLING_ITEM_PAGINATED_LIST";
export const SET_BILLING_ITEM_MODEL = "SET_BILLING_ITEM_MODEL";
export const BILLING_ITEM_MODEL_UPDATED_BY_FORM = "BILLING_ITEM_MODEL_UPDATED_BY_FORM";
export const NEW_BILLING_ITEM_CREATED_ON_SERVER = "NEW_BILLING_ITEM_CREATED_ON_SERVER";
export const EXISTING_BILLING_ITEM_UPDATED_ON_SERVER = "EXISTING_BILLING_ITEM_UPDATED_ON_SERVER";

//Billing Activities
export const SET_BILLING_ACTIVITY_PAGINATED_LIST = "SET_BILLING_ACTIVITY_PAGINATED_LIST";
export const SET_BILLING_ACTIVITY_MODEL = "SET_BILLING_ACTIVITY_MODEL";
export const BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM = "BILLING_ACTIVITY_MODEL_UPDATED_BY_FORM";
export const NEW_ITEM_ADDED_TO_ACTIVITY = "NEW_ITEM_ADDED_TO_ACTIVITY";
export const EXISTING_ITEM_UPDATED_FROM_ACTIVITY = "EXISTING_ITEM_UPDATED_FROM_ACTIVITY";

//Billing Entry
export const SET_BILLING_ENTRY_PAGINATED_LIST = "SET_BILLING_ENTRY_PAGINATED_LIST";
export const MODIFY_BILLING_ENTRY = "MODIFY_BILLING_ENTRY";
export const DELETE_BILLING_ENTRY = "DELETE_BILLING_ENTRY";
// export const SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES = "SET_BILLING_ENTRY_PAGE_PREDIFIEND_QUERIES";
// export const CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES = "CREATE_BILLING_ENTRY_PAGE_PREDIFINED_QUERIES";

//Integration API reducers
export const SET_API_PAGINATED_LIST = "SET_API_PAGINATED_LIST";
export const SET_API_MODEL = "SET_API_MODEL";
export const API_MODEL_UPDATED_BY_FORM = "API_MODEL_UPDATED_BY_FORM";

//Locations
export const SET_LOCATIONS_PAGINATED_LIST = "SET_LOCATIONS_PAGINATED_LIST";
export const LOCATION_SAVED_ADD_TO_LIST = "LOCATION_SAVED_ADD_TO_LIST";

//Licenses
export const SET_LICENSES_PAGINATED_LIST = "SET_LICENSES_PAGINATED_LIST";
export const LICENSE_SAVED_ADD_TO_LIST = "LICENSE_SAVED_ADD_TO_LIST";
export const LICENSE_DELETED = "LICENSE_DELETED";
export const LICENSE_MOVED = "LICENSE_MOVED";

//Serials
export const SET_SERIALS_PAGINATED_LIST = "SET_SERIALS_PAGINATED_LIST";
export const SET_SERIAL_MODEL_FROM_SERVER = "SET_SERIAL_MODEL_FROM_SERVER";
export const SERIAL_UPDATED_BY_FORM = "SERIAL_UPDATED_BY_FORM";
export const ADD_SINGLE_NUMBER_TO_SERIAL = "ADD_SINGLE_NUMBER_TO_SERIAL";
export const ADD_MANY_NUMBER_TO_SERIAL = "ADD_MANY_NUMBER_TO_SERIAL";
export const UPDATE_SINGLE_NUMBER_TO_SERIAL = "UPDATE_SINGLE_NUMBER_TO_SERIAL";
export const DELETE_SINGLE_NUMBER_TO_SERIAL = "DELETE_SINGLE_NUMBER_TO_SERIAL";

//Shipments & Fulfillments
export const NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT = "NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT";
export const ATTACHMENT_DELETED_FROM_SHIPMENT = "ATTACHMENT_DELETED_FROM_SHIPMENT";
export const SET_SHIPMENTS_PAGINATED_LIST = "SET_SHIPMENTS_PAGINATED_LIST";
export const SET_SHIPMENT_MODEL_FROM_SERVER = "SET_SHIPMENT_MODEL_FROM_SERVER";
export const SHIPMENT_MODEL_PROPERTY_UPDATED = "SHIPMENT_MODEL_PROPERTY_UPDATED";
export const SET_SHIPMENT_PAGE_PREDIFIEND_QUERIES = "SET_SHIPMENT_PAGE_PREDIFIEND_QUERIES";
export const CREATE_SHIPMENT_PAGE_PREDIFINED_QUERIES = "CREATE_SHIPMENT_PAGE_PREDIFINED_QUERIES";
export const SHIPMENT_LIST_LINE_ACTION_CLICKED = "SHIPMENT_LIST_LINE_ACTION_CLICKED";
export const SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL = "SET_SHIPMENT_LINE_TO_FULFILLMENT_MODEL";
export const FULFILLMENT_RESOURCE_LICENSES_LOADED = "FULFILLMENT_RESOURCE_LICENSES_LOADED";
export const FULFILLMENT_RESOURCE_LOTS_LOADED = "FULFILLMENT_RESOURCE_LOTS_LOADED";
export const FULFILLMENT_RESOURCE_SERIALS_LOADED = "FULFILLMENT_RESOURCE_SERIALS_LOADED";
export const FULFILLMENT_MODAL_CLOASED = "FULFILLMENT_MODAL_CLOASED";
export const FULFILLMENT_MODEL_UPDATED_BY_FORM = "FULFILLMENT_MODEL_UPDATED_BY_FORM";
export const SHIPMENT_LINE_FULFILLMENT_SAVED = "SHIPMENT_LINE_FULFILLMENT_SAVED";
export const NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT = "NEW_LOT_ADDED_TO_INBOUND_FULFILLMENT";
export const FULFILLMENT_LINE_DELETED = "FULFILLMENT_LINE_DELETED";
export const SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM = "SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM";
export const SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM = "SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM";
export const SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER = "SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER";
export const SHIPPO_PURCHASED_UNDER_SHIPMENT = "SHIPPO_PURCHASED_UNDER_SHIPMENT";
export const SHIPPO_VOIDED = "SHIPPO_VOIDED";
export const SHIPMENT_SHIPSTATION_STATUS_CHANGED = "SHIPMENT_SHIPSTATION_STATUS_CHANGED";
export const SHIPMENT_COMMENT_ADDED = "SHIPMENT_COMMENT_ADDED";