import _ from 'lodash';
import {
    SET_INVENTORIES_PAGINATED_LIST,
    SET_INVENTORY_PAGE_PREDIFIEND_QUERIES,
    CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES,
    SET_INVENTORY_MODEL_FROM_SERVER,
    ADD_UOM_TO_INVENTORY,
    UPDATE_UOM_TO_INVENTORY,
    DELETE_UOM_TO_INVENTORY,
    INVENTORY_UPDATED_BY_FORM,
    STORE_ITEM_TO_KIT_INVENTORY,
    UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE,
    DELETE_ITEM_TO_KIT_INVENTORY
} from './types';
import {api} from '../api';
import Lara from '../lara';

export const fetchPaginatedList = (path) => (dispatch) => {
    return api.inventories.paginated(path).then(res => {
        dispatch({
            type: SET_INVENTORIES_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}

//------Start: July 18, 2020 Update-----//
export const fetchPaginatedListBasicView = (path) => (dispatch) => {
    return api.inventories.paginatedQuickView(path).then(res => {
        dispatch({
            type: SET_INVENTORIES_PAGINATED_LIST,
            payload: res.data.data
        });
        return res;
    }).catch(err => {throw err});
}
//------End: July 18, 2020 Update-----//


export const fetchPredefinedRoutes = (pg = null) => (dispatch) => {
    return api.preferences.getPaginationPreference(pg ? pg : 'Inventory').then(res => {
        const data = res.data.data;
        const payload = [];
        payload.unshift({
            key: 'default',
            value: 'default',
            text: 'Default All Inventry List'
        });
        _.each(data, q => {
            payload.push({
                key: q.id,
                value: _.toString(q.id),
                text: q.name,
                query: q.value
            });
        });
        dispatch({
            type: SET_INVENTORY_PAGE_PREDIFIEND_QUERIES,
            payload: payload
        })
    });
}

//***** Update July 18, 2020 for Basic Inventory View
export const saveInventoryQuery = (name, query, pg = null) => (dispatch) => {
    return api.preferences.createPaginationPreference({
        name: name,
        param: query,
        page: pg ? pg : 'Inventory'
    }).then(res => {
        dispatch({
            type: CREATE_INVENTORY_PAGE_PREDIFINED_QUERIES,
            payload: res.data.data
        });
        return res;
    }).catch(err => {
        throw err;
    })
}

export const fetchById = (id) => (dispatch) => {
    return api.inventories.read(id).then(res => {
        dispatch({
            type: SET_INVENTORY_MODEL_FROM_SERVER,
            payload: res.data.data
        });
        return res.data.data
    }).catch(err => {
        throw err;
    });
}

export const updateModelInStore = (field, value, subObjectField) => {
    return ({
        type: INVENTORY_UPDATED_BY_FORM,
        payload: {
            field, value, subObjectField
        }
    });
}

export const createNewModelInStore = () => {
    return ({
        type: SET_INVENTORY_MODEL_FROM_SERVER,
        payload: Lara.models.inventory()
    });
}

export const updateModelOnServer = (model) => (dispatch) => {
    if (model.id) {
        return api.inventories.update(model.id, model).then(res => {
            return res.data.data;
        }).catch(err => {throw err});
    } else {
        return api.inventories.create(model).then(res => {
            return res.data.data;
        }).catch(err => {throw err});
    }
}

export const storeUomToInventory = (data, inventoryId, index, uomModel) => (dispatch) => {
    uomModel.pivot = {
        inventory_id: inventoryId,
        uom_id: uomModel.id,
        is_base: data.is_base,
        qty_per_base: data.qty_per_base,
        weight: data.weight,
        length: data.length,
        width: data.width,
        height: data.height
    };
    return api.inventories.storeUom(inventoryId, data).then(res => {
        if (index === -1) {
            dispatch({
                type: ADD_UOM_TO_INVENTORY,
                payload: uomModel
            });
        } else {
            dispatch({
                type: UPDATE_UOM_TO_INVENTORY,
                payload: {
                    index: index,
                    data: uomModel
                }
            })
        }
        return res;
    }).catch(err => {
        throw err;
    });
}

export const deleteUomToInventory = (inventoryId, uomId, index) => (dispatch) => {
    return api.inventories.deleteUom(inventoryId, uomId).then(res => {
        dispatch({
            type: DELETE_UOM_TO_INVENTORY,
            payload: index
        });
        return res;
    }).catch(err => {throw err});
}

export const storeItemToInventoryKit = (kitId, itemId, qty, inventoryModel) => (dispatch) => {
    return api.inventories.storeItemToKit(kitId, qty, itemId).then(res => {
        inventoryModel.pivot = {
            kit_id: kitId,
            item_id: itemId,
            item_qty: qty
        }
        dispatch({
            type: STORE_ITEM_TO_KIT_INVENTORY,
            payload: inventoryModel
        });
        return res;
    }).catch(err => {throw err});
}

export const updateItemQtyInInventoryKitInStore = (qty, index) => {
    return {
        type: UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE,
        payload: {
            index: index,
            qty:qty
        }
    }
}

export const updateItemQtyInInventoryKitOnServer = (kitId, itemId, qty) => (dispatch) => {
    return api.inventories.storeItemToKit(kitId, qty, itemId).then(res => {
        return res;
    }).catch(err => {throw err});
}

export const deleteItemFromInventoryKit = (kitId, itemId, index) => (dispatch) => {
    return api.inventories.deleteItemFromKit(kitId, itemId).then(res => {
        dispatch({
            type: DELETE_ITEM_TO_KIT_INVENTORY,
            payload: {
                index: index
            }
        });
        return res;
    }).catch(err => {throw err});
}
