import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import update from 'immutability-helper';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Table} from 'semantic-ui-react';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import AddressDisplay from '../includes/partials/AddressDisplay';
import ModalWrapper from '../includes/partials/ModalWrapper';
import FileUploadButton from '../includes/partials/FileUploadButton';
import ShipmentAttachmentList from '../includes/partials/ShipmentAttachmentList';
import ShipmentCommentForm from './parts/ShipmentCommentForm';
import ShipmentCommentList from '../includes/partials/ShipmentCommentList';
import ListPrintMenu from '../shipments/components/ListPrintMenu';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import {api} from '../../api';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class OrderShipmentViewPage extends Component {
    constructor() {
        super();
        this.state = {
            model: {
                accounting: {},
                billing_addr: {},
                courier: {},
                order: {
                    id: null,
                    type: null,
                    reference: null
                },
                shipping: {},
                shipping_addr: {},
                tracking: {},
                shipment_lines: []
            },
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            orderType: 'Customer Order',
            uploadModal: false,
            commentModal: false,
            comments: []
        }
    }

    componentDidMount() {
        const {match} = this.props;
        const {orderId, shipmentId} = match.params;
        this.setState({
            loading: true
        });

        api.order.shipment(orderId, shipmentId).then(res => {
            const {data} = res.data;
            const {type} = data.order;
            const backTo = `/orders/${type.replace(" ","-")}/${data.order_id}/action/view`
            this.setState({
                model: data,
                loading: false,
                orderType: data.order.type,
                breadcrub: this.getBreadCrub(backTo, data.order.reference, data.order.type, data.id)
            });
            this.fetchComments(data);
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
    }

    fetchComments = (data) => {
        api.loadComment(data.id, 'Shipment').then(res => {
            this.setState({
                comments: res.data.data
            })
        })
    }

    getBreadCrub = (backTo, name, type, shipmentId) => {
        if (!backTo) {
            backTo = `/orders/${type}/default/${LaraPagination.relations(false, type.replace('-',' '))}`;
        }
        return [
            {isLink:false, to:null, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:true, to: backTo, tag: name, active: false, key: 2},
            {isLink:false, to: null, tag: 'Shipment', active: true, key: 3},
            {isLink:false, to: null, tag: shipmentId, active: true, key: 4},
        ]
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                shipmentPanelLoading: false
            });
        } else {
            this.setState({
                shipmentPanelLoading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    getTotalUnits = () => {
        const {shipment_lines} = this.state.model;
        let totalUnits = 0;
        _.each(shipment_lines, line => {
            totalUnits = totalUnits + Math.abs(line.quantity);
        });
        return totalUnits
    }

    onModalClose = (field, status) => {
        this.setState({
            [field]: status
        })
    }

    fileUploaded = (data) => {
        this.setState({
            model: update(this.state.model, {
                files: {$push: [data.data]}
            })
        })
    }

    modelFileDeleted = (index) => {
        this.setState({
            model: update(this.state.model, {
                files: {$splice: [[index, 1]]}
            })
        })
    }

    commentCreated = (data) => {
        this.setState({
            comments: update(this.state.comments, {$push: [data.data]})
        })
    }

    getListItemSelected = () => {
        return [this.state.model.id]
    }

    render() {
        const {model} = this.state;
        const {t} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.id ? _.toString(model.id) : "Loading..."} Buttons={
                                () => {return (<React.Fragment>
                                    <div style={{float:'right'}}>
                                        <ListPrintMenu
                                            fulfillmentType={
                                                this.state.orderType === 'Customer Order' ? 'outbound' : 'inbound'
                                            }
                                            getListItemSelected={() => {
                                                return this.getListItemSelected()
                                            }}
                                            shipmentReport={true}
                                            reFethcRcords={() => {
                                                return null;
                                            }}
                                            modelId={model.id}
                                            adminList={false}
                                            isLp={false}
                                        />
                                    </div>
                                </React.Fragment>)}
                            }/>
                            <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Reference"
                                        value={model.order.reference} />
                                    <ResourceTextPropertyView
                                        label="Status"
                                        value={model.status} />
                                    <AddressDisplay
                                        tooltipId='billToolTip'
                                        addressType='Bill'
                                        orderType={this.state.orderType}
                                        address={model.billing_addr}
                                    />
                                    <AddressDisplay
                                        tooltipId='shipToolTip'
                                        addressType='Ship'
                                        orderType={this.state.orderType}
                                        address={model.shipping_addr}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="ShipDate"
                                        value={model.ship_date} />
                                    <ResourceTextPropertyView
                                        label="CancelDate"
                                        value={model.cancel_date} />
                                    <ResourceTextPropertyView
                                        label="CompletedDate"
                                        value={model.finished_date} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="PTNumber"
                                        value={model.pt_number} />
                                    <ResourceTextPropertyView
                                        label="Courier"
                                        value={model.courier ? model.courier.name : ""} />
                                    <ResourceTextPropertyView
                                        label="TrackingNumber"
                                        value={model.tracking.waybill} />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                                <Table compact>
                                    <Table.Header>
                                        <Table.Row>
                                            <Table.HeaderCell width={2}>
                                                {t('SKU')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={2}>
                                                {t('UOM')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={9}>
                                                {t('Name')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={2}>
                                                {t('OrderQty')}
                                            </Table.HeaderCell>
                                            <Table.HeaderCell width={1}>
                                                {t('Processed')}
                                            </Table.HeaderCell>
                                        </Table.Row>
                                    </Table.Header>
                                    <Table.Body>
                                        {model.shipment_lines.map((line, index) => {
                                            return (
                                                <Table.Row key={line.id}>
                                                    <Table.Cell>
                                                        {line.order_line.sku}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {line.order_line.uom}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {line.order_line.name}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='right'>
                                                        {line.order_line.quantity}
                                                    </Table.Cell>
                                                    <Table.Cell textAlign='right'>
                                                        {Math.abs(line.quantity)}
                                                    </Table.Cell>
                                                </Table.Row>
                                            )
                                        })}
                                        <Table.Row>
                                            <Table.Cell colSpan={4} textAlign='right'>
                                                Total Units
                                            </Table.Cell>
                                            <Table.Cell textAlign='right'>
                                                {this.getTotalUnits()}
                                            </Table.Cell>
                                        </Table.Row>
                                    </Table.Body>
                                </Table>
                                </div>
                            </div>
                        </Segment>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 lara-secondary-segment-wrapper">
                        {model.id ? <Segment>
                        <SegmentRibbon label={t('Comments')} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Add')}
                                        onClick={() => {this.setState({commentModal: true})}}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <ShipmentCommentList
                                comments={this.state.comments}
                                t={t}
                            />
                        </Segment> :  null}
                        {model.id ? <Segment>
                            <SegmentRibbon label={t('Attachments')} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Add')}
                                        onClick={() => {this.setState({uploadModal: true})}}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <ShipmentAttachmentList
                                files={model.files}
                                t={t}
                                onFileDeleted={(index) => {
                                    this.modelFileDeleted(index);
                                }}
                                user={this.props.user}
                            />
                        </Segment> :  null}
                    </div>
                </div>
                <ModalWrapper
                    showModal={this.state.uploadModal}
                    onModalClose={() => {
                        this.onModalClose('uploadModal', false)
                    }}
                    SubComponent={() => {
                        return <FileUploadButton
                            name={t('Upload')+' '+t('Attachments')}
                            type='file'
                            filable_id={model.id}
                            filable_type="Shipment"
                            onSuccess={(data) => { this.fileUploaded(data) }}
                            t={this.props.t}
                            comment={true}
                        />
                    }}
                    size='mini'
                />
                <ModalWrapper
                    showModal={this.state.commentModal}
                    onModalClose={() => {
                        this.onModalClose('commentModal', false)
                    }}
                    SubComponent={() => {
                        return <ShipmentCommentForm
                            commentable_id={model.id}
                            commentable_type='Shipment'
                            t={t}
                            commentCreated={(data) => {
                                this.commentCreated(data);
                            }}
                            user={this.props.user}
                        />
                    }}
                    size='mini'
                />
            </div>
        )
    }
}

OrderShipmentViewPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            orderId: PropTypes.string.isRequired,
            shipmentId: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    user: PropTypes.object
};

function mapStateToProps(state) {
    return {
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(OrderShipmentViewPage));
