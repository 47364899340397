import update from 'immutability-helper';
import {
    SET_INVENTORY_MODEL_FROM_SERVER,
    ADD_CATEGORY_TO_INVENTORY,
    DELETE_CATEGORY_TO_INVENTORY,
    ADD_UOM_TO_INVENTORY,
    UPDATE_UOM_TO_INVENTORY,
    DELETE_UOM_TO_INVENTORY,
    INVENTORY_UPDATED_BY_FORM,
    STORE_ITEM_TO_KIT_INVENTORY,
    UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE,
    DELETE_ITEM_TO_KIT_INVENTORY
} from '../../actions/types'

export default function inventoryModel(state = {
    id: null,
    config: {},
    international: {},
    relations: [],
    uoms: [],
    categories: [],
    kits: [],
    quarantines: [],
    fulfillments_total_change_with_quarantine: []
}, action = {}) {
    switch(action.type) {
        case SET_INVENTORY_MODEL_FROM_SERVER:
            return action.payload;
        
        case ADD_CATEGORY_TO_INVENTORY: 
            return update(state, {
                categories: {$push: [action.payload.data]}
            });

        case DELETE_CATEGORY_TO_INVENTORY:
            return update(state, {
                categories: {$splice: [[action.payload.index, 1]]}
            });

        case ADD_UOM_TO_INVENTORY: 
            return update(state, {
                uoms: {$push: [action.payload]}
            });

        case UPDATE_UOM_TO_INVENTORY:
            return update(state, {
                uoms: {
                    [action.payload.index]: {
                        $set: action.payload.data
                    }
                }
            });

        case DELETE_UOM_TO_INVENTORY:
            return update(state, {
                uoms: {$splice: [[action.payload, 1]]}
            });

        case INVENTORY_UPDATED_BY_FORM:
            let {field, value, subObjectField} = action.payload;
            if (!subObjectField) {
                return update(state, {
                    [field]: {$set: value}
                });
            } else {
                return update(state, {
                    [subObjectField]: {
                        [field]: {$set: value}
                    }
                });
            }

        case STORE_ITEM_TO_KIT_INVENTORY:
            return update(state, {
                kits: {$push: [action.payload]}
            });

        case UPDATE_ITEM_QTY_IN_KIT_INVENTORY_IN_STORE:
            return update(state, {
                kits: {
                    [action.payload.index]: {
                        pivot: {
                            item_qty: {$set: action.payload.qty}
                        }
                    }
                }
            });

        case DELETE_ITEM_TO_KIT_INVENTORY:
            return update(state, {
                kits: {$splice: [[action.payload.index, 1]]}
            });

        default: return state;
    }
}