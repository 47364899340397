import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import accounting from 'accounting';
import {
    Table, Segment, Modal, Header,Form, Message
} from 'semantic-ui-react';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PropTypes from 'prop-types';
import BillingItemSelector from '../../includes/selectors/BillingItemSelector';
import update from 'immutability-helper';
import {
    newItemAddedToActivity,
    existingItemModifiedInActivity
} from '../../../actions/billing';
import PrimaryButton from '../../includes/buttons/PrimaryButton';
import {api} from '../../../api';

//Lara Button Updated
class ActivityItemTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            modalOpen: false,
            isNew: false,
            item: null,
            itemIndex: -1,
            loading: false,
            pivot: {

            }
        }
    }

    onModifyItemClick = (item, index) => {
        if (!item) {
            this.setState({
                modalOpen: true,
                item: null,
                isNew: true,
                itemIndex: -1,
                pivot: {
                    rate: 1,
                    quantity: 1,
                    minimum_charge: null,
                    minimum_quantity: null
                }
            })
        } else {
            this.setState({
                modalOpen: true,
                isNew: false,
                item: item,
                itemIndex: index,
                pivot: item.pivot
            })
        }
    }

    onCloseModal = () => {
        this.setState({
            modalOpen: false,
            item: null,
            itemIndex: -1,
            pivot: {
            }
        })
    }

    exceptedIds = () => {
        const {items} = this.props.model;
        const except = [];
        if (_.isArray(items)) {
            _.each(items, item => {
                if(_.isObject(item)) {
                    except.push(item.id);
                }
            });
        }
        return except;
    }
    
    onPivotValueChange = (field, value) => {
        this.setState({
            pivot: update(this.state.pivot, {
                [field]: {$set: value}
            })
        })
    }

    onSaveClick = () => {
        const {model, newItemAddedToActivity, existingItemModifiedInActivity} = this.props;
        const {item, itemIndex, isNew, pivot} = this.state;
        if (!_.isObject(item)) {
            return null;
        }
        if (!item.id) {
            return null;
        }
        if (isNew) {
            const existingIds = this.exceptedIds();
            if (existingIds.includes(item.id)) {
                return null;
            }
            this.setState({
                loading: true
            });
            api.billing.activities.associateItem(model.id, item.id, pivot).then(res => {
                this.setState({
                    loading: false,
                    modalOpen: false
                });
                const newAddedItem = _.cloneDeep(item);
                newAddedItem.pivot = pivot;
                newItemAddedToActivity(newAddedItem);
            })
        } else {
            this.setState({
                loading: true
            });
            api.billing.activities.updateAssociatedItem(model.id, item.id, pivot).then(res => {
                this.setState({
                    loading: false,
                    modalOpen: false
                });
                existingItemModifiedInActivity(itemIndex, pivot);
            })
        }
    }

    render() {
        const {model, t} = this.props;
        return (
            <div className="row lara-row-margin">
                <div className="col-md-12 col-sm-12 col-xs-12">
                    <Segment>
                    <SegmentRibbon label={t('Item')} Buttons={
                        () => {return (<React.Fragment>
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Add')}
                                onClick={() => {
                                    this.onModifyItemClick(null, null)}
                                }
                                loading={false}
                            />
                    </React.Fragment>)} } />
                            <Table compact>
                                <Table.Header>
                                    <Table.Row>
                                        <Table.HeaderCell>
                                            {t('Item')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {t('UOM')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {t('DefaultRate')}
                                        </Table.HeaderCell>
                                        <Table.HeaderCell>
                                            {t('DefaultQuantity')}
                                        </Table.HeaderCell>
                                        {this.props.activityType === 'Daily-Location' ? <Table.HeaderCell>
                                            {t('MinimumQuantity')}
                                        </Table.HeaderCell> : null}
                                        {this.props.activityType === 'Daily-Location' ? <Table.HeaderCell>
                                            {t('MinimumRate')}
                                        </Table.HeaderCell> : null}
                                    </Table.Row>
                                </Table.Header>
                                <Table.Body>
                                    {model.items.map( (i, index) => {
                                        return (<Table.Row key={i.id}>
                                            <Table.Cell>
                                                <span className="lara-editable lara-clickable" onClick={() => {
                                                    this.onModifyItemClick(i, index);
                                                }}>
                                                    {i.name}
                                                </span>
                                            </Table.Cell>
                                            <Table.Cell>
                                                {i.uom}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {accounting.formatMoney(i.pivot.rate)}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {accounting.formatNumber(i.pivot.quantity)}
                                            </Table.Cell>
                                            {this.props.activityType === 'Daily-Location' ? <Table.Cell>
                                                {accounting.formatNumber(i.pivot.minimum_quantity)}
                                            </Table.Cell> : null}
                                            {this.props.activityType === 'Daily-Location' ? <Table.Cell>
                                                {accounting.formatMoney(i.pivot.minimum_charge)}
                                            </Table.Cell> : null}
                                        </Table.Row>)
                                    })}
                                </Table.Body>
                            </Table>
                    </Segment>
                </div>

                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal()}}
                    closeOnEscape={true} open={this.state.modalOpen}>
                    <Header icon='pencil' content='' />
                    <Modal.Content>
                        <Message>
                            <Message.Header>{t("Item")}</Message.Header>
                            <p>
                            {t('MessageAddItemToActivity')}
                            </p>
                        </Message>
                        <Form>
                            {this.state.isNew ? <div style={{marginBottom: 8}}>
                                <label className="lara-ui-label">{t('Item')}</label>
                                    <BillingItemSelector
                                        onSelected={(data) => {
                                            this.setState({
                                                item: data
                                            });
                                        }}
                                        exceptIds={this.exceptedIds()}
                                    /></div> : null}
                            <Form.Input
                                type="number"
                                value={this.state.pivot.quantity}
                                onChange={(e, {value}) => {
                                    this.onPivotValueChange('quantity', value)
                                }}
                                label={t("DefaultQuantity")} />
                            <Form.Input
                                type="number"
                                value={this.state.pivot.rate}
                                onChange={(e, {value}) => {
                                    this.onPivotValueChange('rate', value)
                                }}
                                label={t("DefaultRate")} />
                            {this.props.activityType === 'Daily-Location' ? <Form.Input
                                type="number"
                                value={this.state.pivot.minimum_quantity ? this.state.pivot.minimum_quantity : 0}
                                onChange={(e, {value}) => {
                                    this.onPivotValueChange('minimum_quantity', value)
                                }}
                                label={t("MinimumQuantity")} /> : null}
                            {this.props.activityType === 'Daily-Location' ? <Form.Input
                                type="number"
                                value={this.state.pivot.minimum_charge ? this.state.pivot.minimum_charge : 0.00}
                                onChange={(e, {value}) => {
                                    this.onPivotValueChange('minimum_charge', value)
                                }}
                                label={t("MinimumRate")} />: null}
                            <PrimaryButton
                                size='mini'
                                float='right'
                                label={t('Save')}
                                onClick={() => {
                                    this.onSaveClick()
                                }}
                                loading={this.state.loading}
                            />
                        </Form>
                        <div className="row">
                            <div className="lara-row-margin"></div>
                        </div>
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

ActivityItemTable.propTypes = {
    t: PropTypes.func.isRequired,
    activityType: PropTypes.string.isRequired,
    newItemAddedToActivity: PropTypes.func.isRequired,
    existingItemModifiedInActivity: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.billingActivityModel
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        newItemAddedToActivity,
        existingItemModifiedInActivity
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(ActivityItemTable)