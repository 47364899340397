import React from 'react';
import {List, Label} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {api} from '../../../api';
import Lara from "../../../lara";

class ShipmentAttachmentList extends React.PureComponent {

    onDeleteFileClick = (file, index) => {
        api.deleteFile(file.path).then(res => {
            this.props.onFileDeleted(index)
        })
    }

    onDownloadFileClick = (file) => {
        api.downloadFile(file.path).then(res => {
            const {data} = res.data;
            window.open(data, '_blank');
        })
    }

    onRetryFileClick = (file) => {
        api.retryAstroFile(file).then(res => {
            Lara.alertSuccess("File retried, refresh or come back later for result");
        }).catch(err => {
            Lara.alertError(err.response.data.message);
        })
    }

    render() {
        const {files, t} = this.props;
        return (
            <List divided relaxed>
                {files.map((file, index) => {
                    return (
                        <List.Item key={file.id}>
                            <List.Icon name='file outline' size='large' verticalAlign='middle' />
                            <List.Content>
                                <List.Header>{file.original_name}</List.Header>
                                <List.Description as='a'>
                                    {file.comments} <br />
                                    <Label size='mini' color='teal' onClick={() => {
                                        this.onDownloadFileClick(file)
                                    }}>{t('Download')}</Label>
                                    {this.props.user.is_su || (this.props.user.id === file.user_id) ? <Label size='mini' color='orange' onClick={() => {
                                        this.onDeleteFileClick(file, index);
                                    }}>{t('Delete')}</Label> : null}
                                    <Label size='mini' color='black' onClick={() => {
                                        this.onRetryFileClick(file)
                                    }}>{t('Retry (Astro Only!)')}</Label>
                                </List.Description>
                            </List.Content>
                        </List.Item>
                    )
                })}
            </List>
        )
    }
}

ShipmentAttachmentList.propTypes = {
    files: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired,
    onFileDeleted: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

export default ShipmentAttachmentList
