import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Confirm, List} from 'semantic-ui-react';
import {
    fetchById,
    createFullShipment,
    setPartialShipmentModelFromOrder,
    orderCancelled
} from '../../actions/order';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import ResourceTextPropertyView from '../includes/partials/ResourceTextPropertyView';
import AddressDisplay from '../includes/partials/AddressDisplay';
import RelationDisplay from '../includes/partials/RelationDisplay';
import OrderDisplayTable from './parts/OrderDisplayTable';
import ShipmentList from './parts/ShipmentList';
import CreatePartialShipmentComponent from './parts/CreatePartialShipmentComponent';
import OrderTag from './parts/OrderTag';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import TraceComponent from '../includes/partials/TraceComponent';
import {api} from '../../api';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import SecondaryButton from '../includes/buttons/SecondaryButton';
import DeleteButton from '../includes/buttons/DeleteButton';
import OrderMetaSegment from "../includes/partials/OrderMetaSegment";
import ShipmentCommentList from "../includes/partials/ShipmentCommentList";

class OrderViewPage extends Component {
    constructor() {
        super();
        this.state = {
            modelId: null,
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            orderType: null,
            cancelConfirm: false,
            shipmentPanelLoading: false,
            showPartialShipmentComponent: false,
            cancelBtnLoading: false,
            cloneBtnLoading: false,
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {type, id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                modelId: id,
                loading: false,
                orderType: type,
                breadcrub: this.getBreadCrub(location.backTo, res.reference, type)
            })
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
    }

    getCourierName = () => {
        const {model, couriers} = this.props;
        const courier = Lara.findFromResourceArray(model.default_courier, 'id', couriers, true);
        return courier ? courier.name : ""
    }

    getBreadCrub = (backTo, name, type) => {
        if (!backTo) {
            backTo = `/orders/${type}/default/${LaraPagination.relations(false, type.replace('-',' '))}`;
        }
        return [
            {isLink:true, to: backTo, tag: _.replace(type,'-',' '), active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }

    getCourierName = (courierId) => {
        const {couriers} = this.props;
        const courierIndex = _.findIndex(couriers, c => {
            return parseInt(courierId,10) === parseInt(c.id, 10);
        });
        return courierIndex > -1 ? couriers[courierIndex].name : 'NA'
    }

    canCreateShipments = () => {
        if (this.props.model.status === 'Open') {
            return true;
        }
        const {order_status} = this.props.model;
        if (!order_status) {
            return true;
        }
        if (order_status.has_bo) {
            return true;
        }
        return false;
    }

    onCreatePartialShipmentClick = () => {
        const {showPartialShipmentComponent} = this.state;
        this.props.setPartialShipmentModelFromOrder(showPartialShipmentComponent, this.props.model);
        this.setState({
            showPartialShipmentComponent: !this.state.showPartialShipmentComponent
        });
    }

    onCreateFullShipmentClick = () => {
        const {model, createFullShipment} = this.props;
        this.setState({
            shipmentPanelLoading: true
        });
        createFullShipment(model.id).then(res => {
            this.setState({
                shipmentPanelLoading: false
            });
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({loading: false});
        })
    }

    partialShipmentCreated = () => {
        this.setState({
            showPartialShipmentComponent: false
        });
        this.setState({
            loading: true
        });
        this.props.fetchById(this.props.model.id).then(res => {
            this.setState({
                loading: false
            });
        });
    }

    onCloneClick = () => {
        this.setState({
            cloneBtnLoading: true
        });
        api.order.clone(this.props.model.id).then(res => {
            this.setState({
                cloneBtnLoading: false
            });
            Lara.alertSuccess(this.props.t('MessageOrderCloned'))
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({cloneBtnLoading: false});
        })
    }

    onCancelBoClick = () => {
        this.setState({
            cancelBtnLoading: true
        });
        api.order.cancel(this.props.model.id).then(res => {
            this.setState({
                cancelBtnLoading: false
            });
            this.props.fetchById(this.props.model.id);
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({cancelBtnLoading: false});
        })
    }

    onCancelClick = () => {
        if (this.state.cancelBtnLoading) {
            return null;
        }
        this.setState({
            cancelBtnLoading: true,
            cancelConfirm: false
        });
        api.order.cancel(this.props.model.id).then(res => {
            this.setState({
                cancelBtnLoading: false
            });
            this.props.orderCancelled();
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({cancelBtnLoading: false});
        })
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                shipmentPanelLoading: false
            });
        } else {
            this.setState({
                shipmentPanelLoading: false
            });
            Lara.axiosAlert(<AlertComponent
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    segmentBtnGroupOrder = () => {
        const {model, t} = this.props;
        const {order_status} = model;
        let hasBo = false;
        if (order_status) {
            if (order_status.has_bo && order_status.status === 'Processed') {
                hasBo = true;
            }
        }
        return <React.Fragment>
            {model.status === 'Open' ? <PrimaryNaviButton
                link={`/orders/${this.state.orderType}/${model.id}/action/edit`}
                size='mini'
                float='right'
                loading={false}
                label={t('Edit')}
            /> : null}
            {model.status === 'Open' ? <DeleteButton
                size='mini'
                float='right'
                label={t('Cancel')}
                onClick={() => {
                    this.setState({
                        cancelConfirm: true
                    })
                }}
                loading={this.state.cancelBtnLoading}
            /> : null}
            {model.status !== 'Cancelled' ? <SecondaryButton
                size='mini'
                float='right'
                label={t('Clone')}
                onClick={() => {this.onCloneClick()}}
                loading={this.state.cloneBtnLoading}
            /> : null}
            {hasBo ? <SecondaryButton
                size='mini'
                float='right'
                label={t('Cancel BO')}
                onClick={() => { this.onCancelBoClick() }}
                loading={this.state.cancelBtnLoading}
            /> : null}
        </React.Fragment>
    }

    hasFBA = () => {
        const {user, clients} = this.props;
        let clientId = 0;
        if (user.is_main_user) {
            clientId = user.last_working_client
        } else {
            clientId = user.client_id
        }
        const clientIndex = _.findIndex(clients, c => {
            return _.toString(c.id) === _.toString(clientId);
        })
        if (clientIndex > -1) {
            return clients[clientIndex].is_fba
        }
        return null
    }

    onSubmitFbaInboundPlanClick = () => {
        //
        const {model} = this.props;
        this.setState({
            loading: true
        });
        api.fba.inboundPlan(model.id).then(res => {
            if (res.data.data === 'success') {
                this.props.fetchById(model.id).then(res => {
                    this.setState({
                        loading: false
                    })
                })
            } else {
                Lara.alertError("Cannot save shipments to the system. It might be caused by insufficient inventories");
                this.setState({
                    loading: false
                })
            }
        }).catch(err => {
            this.handleHttpErrors(err);
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                    {model.id && this.props.user.is_main_user ? <div className="col-md-4 col-sm-4 col-xs-12 lara-breadcrub">
                        <TraceComponent
                            trace_id={model.id}
                            trace_type='App\Models\Order'
                        />
                    </div> : null}
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={model.reference ? model.reference : "Loading..."} Buttons={
                                () => {return (this.state.showPartialShipmentComponent
                                    ? null
                                    : this.segmentBtnGroupOrder())}
                            }/>
                            {model.id && !this.state.showPartialShipmentComponent ? <div className="row">
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <RelationDisplay
                                        label={this.state.orderType && this.state.orderType.includes('Customer') ? t('Customer') : t('Vendor')}
                                        relation={model.relation}
                                        tooltipId='relationToolTip'
                                        source={model.source.source}
                                    />
                                    <AddressDisplay
                                        tooltipId='billToolTip'
                                        addressType='Bill'
                                        orderType={model.type}
                                        address={model.billing_address}
                                    />
                                    <AddressDisplay
                                        tooltipId='shipToolTip'
                                        addressType='Ship'
                                        orderType={model.type}
                                        address={model.shipping_address}
                                    />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Status"
                                        value={model.status} />
                                    <ResourceTextPropertyView
                                        label="Reference"
                                        value={model.reference} />
                                    <ResourceTextPropertyView
                                        label="PO"
                                        value={model.po} />
                                </div>
                                <div className="col-md-4 col-sm-6 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="Courier"
                                        value={this.getCourierName(model.shipping.courier_id)} />
                                    <ResourceTextPropertyView
                                        label="CourierPaymentMethod"
                                        value={t(_.upperFirst(model.shipping.payment))} />
                                    <ResourceTextPropertyView
                                        label="CourierAccount"
                                        value={model.shipping.collect_account.account} />
                                </div>

                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <OrderDisplayTable
                                        t={t}
                                        lines={model.lines}
                                        accounting={model.accounting}
                                    />
                                </div>


                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="WarehouseNote"
                                        value={model.note} />
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <ResourceTextPropertyView
                                        label="DocumentNote"
                                        value={model.print_note} />
                                </div>
                            </div> : null}

                            {model.id && this.state.showPartialShipmentComponent ?
                                <CreatePartialShipmentComponent shipmentCreated={() => {
                                    this.partialShipmentCreated();
                                }} orderId={model.id}/>
                            : null}
                        </Segment>
                    </div>
                    <div className="col-md-4 col-sm-4 col-xs-12 lara-secondary-segment-wrapper">
                        {model.id && model.status !== 'Cancelled' ? <Segment>
                            <Dimmer inverted active={this.state.shipmentPanelLoading || this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={t('Shipment')} Buttons={
                                () => {return (<React.Fragment>
                                    {this.canCreateShipments() ? <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Create')}
                                        onClick={() => {this.onCreatePartialShipmentClick()}}
                                        loading={this.state.loading}
                                    /> : null}
                                        <ShipmentList
                                            history={this.props.history}
                                            t={t}
                                            shipments={model.shipments}
                                            orderId={model.id}
                                        />
                                        {model.status === 'Open' ?
                                            <div>
                                                Click
                                                <span className='lara-editable lara-clickable' onClick={() => {
                                                    this.onCreateFullShipmentClick()
                                                }}> here </span>
                                                to create a full shipment for the entire order
                                            </div>
                                        : null}
                                </React.Fragment>)}
                            }/>
                        </Segment> :  null}
                        {model.id && model.status === 'Open' && model.type === 'Customer Order' && this.hasFBA() ? <Segment>
                        <Dimmer inverted active={this.state.loading}>
                            <Loader />
                        </Dimmer>
                        <SegmentRibbon label='FBA' Buttons={
                                () => {return (<React.Fragment>
                                    {model.status === 'Open' && this.canCreateShipments() ? <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Create')}
                                        onClick={() => {this.onSubmitFbaInboundPlanClick()}}
                                        loading={this.state.loading}
                                    /> : null}
                                </React.Fragment>)}
                            }/>
                            Submit inbound plan to FBA by clicking above button. Please make sure
                            there are enough inventories to create the entire order. Insufficient
                            inventories may cause issues in your FBA system.
                        </Segment> :  null}
                        {/*{_.isObject(model.meta) ? <Segment>*/}
                        {/*    <SegmentRibbon label={t('Meta Fields')} Buttons={null}/>*/}
                        {/*    <List>*/}
                        {/*        {Object.keys(model.meta).map(field => {*/}
                        {/*            return _.isString(model.meta[field]) || _.isBoolean(model.meta[field]) ? <List.Item key={field}>*/}
                        {/*                <List.Header>{_.upperCase(field)}</List.Header>*/}
                        {/*                {_.isBoolean(model.meta[field]) ? <span>*/}
                        {/*                    {model.meta[field] ? 'Yes' : 'No'}*/}
                        {/*                </span> : model.meta[field]}*/}
                        {/*            </List.Item> : null*/}
                        {/*        })}*/}
                        {/*    </List>*/}
                        {/*</Segment> : null}*/}
                        {model.id && _.isObject(model.meta) ? <OrderMetaSegment model={model}/> : null}
                        {model.id ? <Segment>
                            <SegmentRibbon label={t('Category')} Buttons={null}/>
                            <OrderTag
                                categories={model.categories}
                                t={t}
                                id={model.id}
                            />
                        </Segment> :  null}

                        {model.id ? <Segment>
                            <SegmentRibbon label={t('Comments')} Buttons={
                                () => {return null}
                            }/>
                            <ShipmentCommentList
                                orderNote={model.note}
                                comments={_.isArray(model.comments) ? model.comments : []}
                                t={t}
                            />
                        </Segment> :  null}

                    </div>
                </div>
                <Confirm open={this.state.cancelConfirm}
                    content={this.state.cancelBtnLoading ? <Loader /> : t('CancelAlert')}
                    onCancel={() => {
                        this.setState({
                            cancelConfirm: false
                        })
                    }}
                    cancelButton={t('Go Back')}
                    confirmButton={t('Confirm')}
                    onConfirm={() => this.onCancelClick()}
                />
            </div>
        )
    }
}

OrderViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    createFullShipment: PropTypes.func.isRequired,
    setPartialShipmentModelFromOrder: PropTypes.func.isRequired,
    orderCancelled: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            type: PropTypes.string.isRequired,
            id: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    couriers: PropTypes.array.isRequired,
    clients: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.orderModel,
        couriers: state.system.resources.couriers,
        user: state.system.user,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        createFullShipment,
        setPartialShipmentModelFromOrder,
        orderCancelled
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(OrderViewPage));
