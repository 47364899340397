import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import {withI18n} from 'react-i18next';

const ClientNameDisplay = ({companyName, tooltipId}) => (
    <div className="lara-text-wrapper">
        <span
            className='lara-product-tooltip'
            data-tip data-for={tooltipId}
        >{companyName.length > 28 ? companyName.substring(0,28)+"..." : companyName}</span>
        <ReactTooltip id={tooltipId} place="bottom" type="dark" effect="solid">
            <div className="lara-text-wrapper">
                <span className="lara-text-block">
                    <span className='lara-text-value'>{companyName}</span>
                </span>
            </div>
        </ReactTooltip>
    </div>
)

ClientNameDisplay.propTypes = {
    tooltipId: PropTypes.string.isRequired,
    companyName: PropTypes.string.isRequired
};


export default withI18n()(ClientNameDisplay);
