import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import { Breadcrumb } from 'semantic-ui-react';

// [{isLink:false, to:null, t: 'Settings', active: false, key:1}]
const PageBreadCrub = ({lists, t}) => (
    <Breadcrumb size='large'>
        {lists.map((list, index) => {
            const {key, active, to, tag} = list;
            return (
                <React.Fragment key={key}>
                    <Breadcrumb.Section link={false} active={active}>
                        {to ? <Link to={to}>{t(tag)}</Link> : t(tag)}
                    </Breadcrumb.Section>
                    {index === (lists.length - 1) ? null : <Breadcrumb.Divider
                        icon='right chevron'
                    ></Breadcrumb.Divider>}
                </React.Fragment>
            )
        })}
    </Breadcrumb>
)

PageBreadCrub.propTypes = {
    lists: PropTypes.array.isRequired,
    t: PropTypes.func.isRequired
};


export default PageBreadCrub;