import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import {Form, Segment, List} from 'semantic-ui-react';
import ClientFormSelector from '../includes/selectors/ClientFormSelector';
import InventorySelector from '../includes/selectors/InventorySelectorReport';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import {api} from '../../api';
import ExcelUpload from '../includes/excel/Main'
import PrimaryButton from '../includes/buttons/PrimaryButton';

const ReportMain = [
    // {value: 'inventory-count-sheet', text: 'Inventory Count Sheet'},
    // {value: 'unlicensed-inventory', text: 'Unlicensed Inventories'},
    // {value: 'all-inventory', text: 'All Inventory Report'},
    // {value: 'available-qty-inventory', text: 'Available Inventory Report'},
    // {value: 'active-inventory', text: 'Active Inventory Report'},
    // {value: 'disabled-inventory', text: 'Disabled Inventory Report'},
    // {value: 'back-inventory', text: 'Back Dated Inventory Report'},
    {value: 'inventory-stock-report', text: 'Inventory On Hand Report'},
    {value: 'inventory-movement-date', text: 'Inventory Movement Report'},
    {value: 'inventory-movement-summary', text: 'Inventory Movement Summary Report'},
    // {value: 'inventory-uom-report', text: 'Inventory UOM Report'},
    // {value: 'inventory-license-report', text: 'Licensed Inventory Report'},
    {value: 'inventory-disposal-date', text: 'Inventory Disposal Report (*Test Version)'},
    {value: 'open-customer-order', text: 'Open Customer Order Report'},
    {value: 'outbound-in-process', text: 'In Process Outbound Shipment Report'},
    {value: 'transaction-date', text: 'Shipment Transaction Report'},
    {value: 'transaction-detailed-date', text: 'Detailed Transaction Report'},
    {value: 'receiving-detailed-date', text: 'Detailed Receiving Report'},
    {value: 'outbound-order-date', text: 'Outbound Order Status Report'},
    {value: 'processed-return-date', text: 'Processed Returns Report'},
    {value: 'serial-number', text: 'Serial Number Lookup'},
    {value: 'quarantine-inventory', text: 'Quarantined Inventory Report (Beta)'},
    {value: 'quarantine-movement', text: 'Quarantined Inventory Movement Report (Beta)'},
    {value: 'aftership-date', text: 'Aftership CSV For All Clients (Need select any client in the form)'},
    {value: 'ms-custom-rpt-1', text: 'MS New Item Report'}
];

const ReportClient = [
    // {value: 'all-inventory', text: 'All Inventory Report'},
    // {value: 'available-qty-inventory', text: 'Available Inventory Report'},
    // {value: 'active-inventory', text: 'Active Inventory Report'},
    // {value: 'disabled-inventory', text: 'Disabled Inventory Report'},
    {value: 'inventory-stock-report', text: 'Inventory On Hand Report'},
    // {value: 'back-inventory', text: 'Back Dated Inventory Report'},
    {value: 'inventory-movement-date', text: 'Inventory Movement Report'},
    {value: 'inventory-movement-summary', text: 'Inventory Movement Summary Report'},
    {value: 'inventory-uom-report', text: 'Inventory UOM Report'},
    // {value: 'inventory-disposal-date', text: 'Inventory Disposal Report (*Test Version)'},
    {value: 'open-customer-order', text: 'Open Customer Order Report'},
    {value: 'transaction-date', text: 'Shipment Transaction Report'},
    {value: 'transaction-detailed-date', text: 'Detailed Transaction Report'},
    {value: 'receiving-detailed-date', text: 'Detailed Receiving Report'},
    {value: 'outbound-order-date', text: 'Outbound Order Status Report'},
    {value: 'processed-return-date', text: 'Processed Returns Report'},
    {value: 'serial-number', text: 'Serial Number Lookup'},
    {value: 'quarantine-inventory', text: 'Quarantined Inventory Report (Beta)'},
    {value: 'quarantine-movement', text: 'Quarantined Inventory Movement Report (Beta)'}
];

class OperationReportPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Report', active: true, key: 1}
            ],
            loading: false,
            model: {
                clientId: "",
                name: "",
                from_date: "",
                to_date: "",
                text_criteria_1: "",
                format: 'excel',
                params: []
            },
            items: [

            ],
            countSheetModal: false
        }
    }

    componentDidMount() {
        const {user} = this.props;
        if (!user.is_main_user) {
            this.setState({
                model: {
                    clientId: user.client_id,
                    name: "",
                    from_date: "",
                    to_date: "",
                    text_criteria_1: "",
                    format: 'excel',
                    params: []
                }
            })
        }
    }

    getExceptIds = (isRequest = false) => {
        const {items} = this.state;
        const except = [];
        if (_.isArray(items)) {
            _.each(items, item => {
                if(_.isObject(item)) {
                    except.push(item.id);
                }
            });
        }
        return except;
    }

    onInventoryItemSelected = (item) => {
        const index = _.findIndex(this.state.items, i => {
            return _.toInteger(i.id) === _.toInteger(item.id);
        })
        if (index > -1) {
            return;
        } else {
            this.setState({
                items: update(this.state.items, {
                    $push: [item]
                })
            })
        }
    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        this.setState({
            loading: false
        });
        Lara.axiosAlert(<AlertComponent
            support={errors.support}
            message={errors.message}
            reference={errors.reference}
            t={this.props.t}
        />);
    }

    onRetrieveDataClick = () => {
        if (!this.state.model.name) {
            return;
        }
        const request = _.cloneDeep(this.state.model);
        request.params = this.getExceptIds();
        // console.log(JSON.stringify(request));
        // return;
        if (this.props.user.is_main_user) {
            if (!request.clientId) {
                return null;
            }
        }
        this.setState({loading: true});

        api.docs.operationReport(request).then(res => {
            // console.log(res.data.data);
            // return;
            if (!_.isEmpty(res.data.data)) {
                Lara.excel.report(res.data.data, this.getReportName() !== 'Aftership_CSV_For_All_Clients_(Need_select_any_client_in_the_form)' ? this.getReportName() : 'AfterShip')
            } else {
                Lara.axiosAlert("No record found");
            }
            this.setState({
                loading: false
            });
        }).catch(err => {
            this.handleHttpErrors(err);
        });
    }

    getReportName = () => {
        if (!this.state.model.name) {
            return;
        }
        const index = _.findIndex(ReportMain, i => {
            return i.value === this.state.model.name
        })
        return index > -1 ? ReportMain[index].text.replace(/ /g,"_") : "Report"
    }

    render() {
        const {t, user, clients} =this.props;
        const {model, items} = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Report')}`} Buttons={null}/>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Form size='mini'>
                                        {user.is_main_user ? <ClientFormSelector
                                            clients={clients}
                                            value={model.clientId}
                                            onSelected={(value) => {
                                                this.setState({
                                                    model: update(model, {
                                                        clientId: {$set: value}
                                                    })
                                                })
                                            }}
                                            size='small'
                                            required={true}
                                            label={t('Client')}
                                        /> : null}
                                        <Form.Select
                                            label={t('ReportName')}
                                            search
                                            required={true}
                                            options={user.is_main_user ? ReportMain : ReportClient}
                                            value={model.name}
                                            onChange={(e,{value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        name: {$set: value}
                                                    })
                                                })
                                            }}
                                        />
                                        {model.name === 'inventory-movement-summary' ? <div className="lara-row-margin" style={{marginBottom: 10}}>
                                            <Form.Input
                                                label={t('Report Code')}
                                                required={false}
                                                value={model.text_criteria_1}
                                                onChange={(e, {value}) => {
                                                    this.setState({
                                                        model: update(model, {
                                                            text_criteria_1: {$set: value}
                                                        })
                                                    })
                                                }}
                                            />
                                        </div> : null}
                                        {model.name === 'inventory-movement-date' && model.clientId ? <div>
                                            <div>
                                                <span className="lara-ui-label">{t("Inventory")} (Search by SKU or Name, then add to list below)</span>
                                                <InventorySelector
                                                    onSelected={(value) => {
                                                        this.onInventoryItemSelected(value)
                                                    }}
                                                    exceptIds={this.getExceptIds()}
                                                    canSearchKit={false}
                                                    clientId={model.clientId}
                                                />
                                            </div>
                                            <div className="lara-row-margin">
                                                <List divided relaxed>
                                                    {items.map((i, index) => {
                                                        return (
                                                            <List.Item key={i.id}>
                                                                <List.Icon name='area chart' size='large' verticalAlign='middle' />
                                                                <List.Content>
                                                                <List.Header>{i.sku}</List.Header>
                                                                <List.Description as='a'>
                                                                    <span onClick={() => {
                                                                        this.setState({
                                                                            items: update(this.state.items, {
                                                                                $splice: [[index, 1]]
                                                                            })
                                                                        })
                                                                    }}>Delete</span>
                                                                </List.Description>
                                                                </List.Content>
                                                            </List.Item>
                                                        )
                                                    })}
                                                </List>
                                            </div>
                                            <div className="lara-row-margin">
                                                <Form.Input
                                                    label={t('Report Code')}
                                                    required={false}
                                                    value={model.text_criteria_1}
                                                    onChange={(e, {value}) => {
                                                        this.setState({
                                                            model: update(model, {
                                                                text_criteria_1: {$set: value}
                                                            })
                                                        })
                                                    }}
                                                />
                                            </div>
                                        </div> : null}
                                        {model.name === 'back-inventory' ? <Form.Input
                                            label={t('Date')}
                                            type='date'
                                            required={true}
                                            value={model.from_date}
                                            onChange={(e, {value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        from_date: {$set: value}
                                                    })
                                                })
                                            }}
                                        /> : null}
                                        {model.name.includes('date') ? <div className="lara-row-margin">
                                            <Form.Input
                                                label={t('StartDate')}
                                                type='date'
                                                required={true}
                                                value={model.from_date}
                                                onChange={(e, {value}) => {
                                                    this.setState({
                                                        model: update(model, {
                                                            from_date: {$set: value}
                                                        })
                                                    })
                                                }}
                                            />
                                            <Form.Input
                                                label={t('EndDate')}
                                                type='date'
                                                required={true}
                                                value={model.to_date}
                                                onChange={(e, {value}) => {
                                                    this.setState({
                                                        model: update(model, {
                                                            to_date: {$set: value}
                                                        })
                                                    })
                                                }}
                                            />
                                        </div> : null}
                                        {model.name === 'serial-number' ? <Form.Input
                                            label={t('SN Number')}
                                            required={true}
                                            value={model.text_criteria_1}
                                            onChange={(e, {value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        text_criteria_1: {$set: value}
                                                    })
                                                })
                                            }}
                                        /> : null}
                                        <Form.Select
                                            label={t('Format')}
                                            required={true}
                                            options={[{value: 'excel', text: 'Excel'}]}
                                            value={model.format}
                                            onChange={(e,{value}) => {
                                                this.setState({
                                                    model: update(model, {
                                                        format: {$set: value}
                                                    })
                                                })
                                            }}
                                        />
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Run')}
                                            onClick={() => {
                                                this.onRetrieveDataClick()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </Form>
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                {this.state.model.name === "inventory-count-sheet"? <div className="row lara-row-margin">
                    <div className="col-md-6 col-sm-6 col-xs-12">
                        <Segment>
                            <SegmentRibbon label='Inventory Count Sheet' Buttons={() => {
                                return (<PrimaryButton size="mini" float="right" label={t('Upload')} loading={this.state.loading} onClick={() => {
                                    this.setState({countSheetModal: true})
                                }}/>)
                            }} />
                        </Segment>
                    </div>
                    <ExcelUpload
                        displayModal={this.state.countSheetModal}
                        onCloseModalClick={() => {
                            this.setState({countSheetModal: false})
                        }}
                        onUploaded={() => {
                            return null;
                        }}
                        type='CountSheet'
                    />
                </div> : null}
            </div>
        )
    }
}

OperationReportPage.propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    clients: PropTypes.array
};

function mapStateToProps(state) {
    return {
        user: state.system.user,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(OperationReportPage))
