import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader,Input, Label, Checkbox, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchPaginatedList,
    fetchPredefinedRoutes,
    saveOrderQuery,
    orderLineActionChecked
} from '../../actions/order';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import FilterBy from '../includes/tables/FilterBy';
import LaraQuery from '../includes/tables/QueryConstructor';
import AlertComponent from '../includes/messages/alert';
import ActionStatusModal from '../includes/partials/ActionStatusModal';
import Lara from '../../lara';
import {api} from '../../api';
import LaraPagination from '../../laraPagination';
import ExcelUpload from '../includes/excel/Main';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import SecondaryButton from '../includes/buttons/SecondaryButton';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import AddressDisplayTable from '../includes/partials/AddressDisplayTable';
import BrtFulfillmentStatusTable from '../includes/partials/BrtFulfillmentStatusTable';

class OrderListPage extends Component {
    constructor() {
        super();
        this.state = {
            orderType: '',
            breadcrub: [
                {isLink:false, to:null, tag: 'Order', active: true, key: 1}
            ],
            queryId: 'default',
            queryName: "",
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            showQueryBuilder: false,
            batchBtnLoading: false,
            batchErrors: [],
            excelModalDisplay: false
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        this.props.fetchPredefinedRoutes(1);
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query, queryId, type}, url) => {
        const q = qs.parse(query);
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        let crub = [
            {isLink:false, to:null, tag: this.props.t('Order'), active: true, key: 1},
            {isLink:false, to:null, tag: _.replace(type,'-',' '), active: true, key: 2}
        ];
        if (q.advanced === 'yes') {
            crub.push({isLink:true, 
                to:`/orders/${type}/default/${LaraPagination.basicOrders(_.replace(type,'-',' '), false)}/basic`, 
                tag: this.props.t('BasicView'), active: true, key: 3});
            crub.push({isLink:false, to:null, tag: this.props.t('AdvancedView'), active: true, key: 4});
        } else {
            crub.push({isLink:false, to:null, tag: this.props.t('BasicView'), active: true, key: 3});
        }
        this.setState({
            breadcrub: crub,
            loading: true,
            queryId: queryId,
            routingQuery: q,
            routingPath: url,
            search: q.search,
            orderType: type,
            advanced: q.advanced
        }, () => {
            this.fetchRecords(query)
            //console.log(this.state);
        });
    }
    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({
                loading: false
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/orders/${this.state.orderType}/${this.state.queryId}/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.orders(_.replace(this.state.orderType,'-',' '), true);
            defaultQuery.search = this.state.search;
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }
    onPredefinedRouteSelected = (value) => {

        const {queries} = this.props;
        let query = LaraPagination.orders(_.replace(this.state.orderType,'-',' '), true);
        if (value !== 'default') {
            const queryIndex = _.findIndex(queries, i => {
                return i.value === value;
            });
            if (queryIndex > 0) {
                query = queries[queryIndex].query;
            } 
        }
        query.advanced = "yes";
        this.setState({
            queryId: value
        }, () => {
            this.routeToNewQuery(query);
        });
    }

    /*
     * Advance Filter Section
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        });
    }
    onQueryConstructorChange = (property, index, value, isRelation) => {
        if (!isRelation) {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            [property]: {$set: value}
                        }
                    }
                })
            });
        } else {
            this.setState({
                routingQuery: update(this.state.routingQuery, {
                    query: {
                        [index]: {
                            relation: {$set: value === 'name' ? 'categories' : null},
                            [property]: {$set: value}
                        }
                    }
                })
            });
        }
    }
    addNewQuery = () => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$push: [{
                    relation: null,
                    field: "sku",
                    operator: "=",
                    value: "",
                    join: "AND"
                }]}
            })
        });
    }
    onRemoveQueryClick = (index) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                query: {$splice: [[index, 1]]}
            })
        });
    }
    runFilter = () => {
        this.routeToNewQuery(this.state.routingQuery);
    }
    saveNewFilter = () => {
        if (this.state.queryName.length <= 0) {
            return null;
        }
        this.props.saveOrderQuery(this.state.queryName, this.state.routingQuery).then(res => {
            this.setState({
                queryId: _.toString(res.data.data.id),
                queryName: ""
            });
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
        });
    }

    onLineActionChecked = ({checked}, index) => {
        this.props.orderLineActionChecked(checked, index);
    }

    onBatchClick = () => {
        const {list} = this.props;
        const ids = [];
        _.each(list.records, item => {
            if (item.action) {
                ids.push(item.id)
            }
        });
        if (ids.length > 0) {
            this.setState({
                batchBtnLoading: true
            });
            api.order.batch(ids).then(res => {
                this.fetchRecords();
                const {data} = res.data;
                if (_.isEmpty(data)) {
                    this.setState({
                        batchBtnLoading: false
                    })
                } else {
                    this.setState({
                        batchBtnLoading: false,
                        batchErrors: data
                    })
                }
            }).catch(err => {
                this.setState({
                    batchBtnLoading: false
                })
            })
        }
    }

    onCloseBatchStatusModal = () => {
        this.setState({
            batchBtnLoading: false
        });
    }

    getRelationName = (row) => {
        if (row.relation.name === "__CustomerByWebService") {
            return _.upperFirst(row.source.source)
        }
        if (row.relation.name === "__VendorByWebService") {
            return "Web Service"
        }
        if (row.relation.name === "__InventoryAdjustment") {
            return "NA"
        }
        return row.relation.name
    }

    render() {
        const {routingQuery, search, showQueryBuilder, queryName} = this.state;
        const {t, list, queries, server_pref} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {
            type: "",
            is_active: ""
        };
        const routeQuries = _.isArray(routingQuery.query) ? routingQuery.query : [];
        const queriableField = LaraPagination.orderQueriable(t);
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Order')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryNaviButton
                                        link={`/orders/${this.state.orderType}/new/action/edit`}
                                        size='mini'
                                        float='right'
                                        loading={false}
                                        label={t('Create')}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Filter')}
                                        onClick={() => {
                                            this.setState({
                                                showQueryBuilder: !this.state.showQueryBuilder
                                            })
                                        }}
                                        loading={false}
                                    />
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label='Excel'
                                        onClick={() => {
                                            this.setState({excelModalDisplay: true})
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder='Search by reference or PO...'
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={(value) => this.onPredefinedRouteSelected(value)}
                                options={queries}
                                query={this.state.queryId}
                                havePreferenceSelect={true}
                            />
                        </Segment>
                        {showQueryBuilder ? <Segment>
                        <SegmentRibbon label={`${t('Advanced Filter')} (Beta)`}/>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <div className="row">
                                    <FilterBy
                                        t={t}
                                        label={t('Status')}
                                        options={[
                                            {value: 'Open', text: t('Open')},
                                            {value: 'Processed', text: t('Processed')},
                                            {value: 'Finished', text: t('Finished')},
                                            {value: 'Cancelled', text: t('Cancelled')},
                                        ]}
                                        name="status"
                                        currentValueString={filterBy.status ? filterBy.status : ""}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                    <FilterBy
                                        t={t}
                                        label={t('BO')}
                                        options={[
                                            {value: '0', text: t('NoBo')},
                                            {value: '1', text: t('HasBO')}
                                        ]}
                                        name="bo"
                                        currentValueString={filterBy.bo ? filterBy.bo : ""}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                </div>
                                <div className="row">
                                    <FilterBy
                                        t={t}
                                        label={t('EnoughStockTOFulfillFilter')}
                                        options={[
                                            {value: '0', text: t('No')},
                                            {value: '1', text: t('Yes')}
                                        ]}
                                        name="can_process"
                                        currentValueString={filterBy.can_process ? filterBy.can_process : ""}
                                        onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                    />
                                </div>
                                <div className="lara-filter-name">{t('Conditions')}</div>
                                {routeQuries.map((q, index) => {
                                    return (
                                        <LaraQuery 
                                            fields={queriableField}
                                            query={q}
                                            index={index}
                                            onQueryConstructorChange={(property, index, value) => {
                                                this.onQueryConstructorChange(property, index, value, false)
                                            }}
                                            onQueryConstructorRelationalChange={(property, index, value) => {
                                                this.onQueryConstructorChange(property, index, value, true)
                                            }}
                                            key={index}
                                            onRemoveQueryClick={() => {this.onRemoveQueryClick(index)}}
                                        />
                                    )
                                })}
                                <div className="row">
                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Conditions')}
                                            onClick={() => {
                                                this.addNewQuery()
                                            }}
                                            loading={this.state.loading}
                                        />
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Run Filter')}
                                            onClick={() => {
                                                this.runFilter()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </div>

                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        <Input fluid 
                                            size='mini'
                                            disabled={this.state.loading}
                                            placeholder='Enter a name and save this filter'
                                            onChange={(e, {value}) => {this.setState({
                                                queryName: value
                                            })}} value={queryName} />
                                    </div>
                                    <div className="col-md-4 col-sm-6 col-xs-12 lara-row-margin">
                                        <PrimaryButton
                                            size='mini'
                                            float='left'
                                            label={t('Save')}
                                            onClick={() => {
                                                this.saveNewFilter()
                                            }}
                                            loading={this.state.loading}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        </Segment> : null}

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('Order')} ${t('List')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Batch')}
                                        onClick={() => {this.onBatchClick()}}
                                        loading={this.state.batchBtnLoading}
                                    />
                                </React.Fragment>)}
                            }/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell />
                                                <Table.HeaderCell>
                                                    {this.state.orderType.includes('Customer') ? t('Customer') : t('Vendor')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Reference'
                                                        routingOrderName='reference'
                                                        currentOrder={orderBy.reference}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('Address')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='PO'
                                                        routingOrderName='po'
                                                        currentOrder={orderBy.po}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                {server_pref.brt_fulfillment_status_in_order_list_bool ? <Table.HeaderCell>
                                                    WH Status
                                                </Table.HeaderCell>: null}
                                                <Table.HeaderCell>
                                                    {t('Status')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('BO')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t('EnoughStockTOFulfill')}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='CreatedAt'
                                                        routingOrderName='created_at'
                                                        currentOrder={orderBy.created_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='UpdatedAt'
                                                        routingOrderName='updated_at'
                                                        currentOrder={orderBy.updated_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {list.records.map((i,index) => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell collapsing>
                                                    <Checkbox slider 
                                                        disabled={i.status === 'Cancelled' || (i.status === 'Processed' && !i.bo) || i.status === 'Finished'}
                                                        checked={i.action}
                                                        onChange={(e,data) => {
                                                            this.onLineActionChecked(data, index);
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {this.getRelationName(i)}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Link to={{
                                                            pathname: `/orders/${this.state.orderType}/${i.id}/action/view`,
                                                            backTo: this.state.routingPath
                                                        }}>
                                                            {i.reference}
                                                        </Link>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <AddressDisplayTable
                                                            tooltipId={`tblToolTip-${i.id}`}
                                                            address={_.isObject(i.shipping_address) ? i.shipping_address : {}}
                                                        />
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.po}
                                                    </Table.Cell>
                                                    {server_pref.brt_fulfillment_status_in_order_list_bool ? <Table.Cell>
                                                        <BrtFulfillmentStatusTable 
                                                            tooltipId={`brtToolTip-${i.id}`}
                                                            meta={_.isObject(i.meta) ? i.meta : {}}
                                                        />
                                                    </Table.Cell>: null}
                                                    <Table.Cell>
                                                        {i.status === 'Open' ? <Label color='grey' size='mini'>{t('Open')}</Label> : null}
                                                        {i.status === 'Processed' ? <Label color='violet' size='mini'>{t('Processed')}</Label> : null}
                                                        {i.status === 'Finished' ? <Label color='green' size='mini'>{t('Finished')}</Label> : null}
                                                        {i.status === 'Cancelled' ? <Label color='orange' size='mini'>{t('Cancelled')}</Label> : null}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.fulfill_status && i.fulfill_status.bo ? <Icon name='star half full' color='orange' /> : null}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {
                                                            i.fulfill_status && i.type === 'Customer Order' && (i.status === "Open" || (i.status === "Processed" && i.fulfill_status.bo))? 
                                                                <span>
                                                                    {i.fulfill_status.can_process ? <Icon name='star' color='green' /> : 
                                                                        <Icon name='star outline' color='orange' />
                                                                    }
                                                                </span>
                                                            : null
                                                        }
                                                        
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.created_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {moment(i.updated_at).format('MMM DD, Y').toString()}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                <ActionStatusModal
                    open={this.state.batchBtnLoading}
                    messages={this.state.batchErrors}
                    onCloseModalClick={() => this.onCloseBatchStatusModal()}
                />
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.fetchRecords();
                    }}
                    type='Order'
                />
            </div>
        )
    }
}

OrderListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            query: PropTypes.string.isRequired,
            queryId: PropTypes.string.isRequired,
            type: PropTypes.string.isRequired
        }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    queries: PropTypes.array.isRequired,
    fetchPaginatedList: PropTypes.func.isRequired,
    fetchPredefinedRoutes: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    saveOrderQuery: PropTypes.func.isRequired,
    orderLineActionChecked: PropTypes.func.isRequired,
    server_pref: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        queries: state.orderList.queries,
        list: state.orderList.list,
        server_pref: state.system.server_pref
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList,
        fetchPredefinedRoutes,
        saveOrderQuery,
        orderLineActionChecked
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(OrderListPage));