import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { ResponsiveLine } from '@nivo/line';
import { ResponsivePieCanvas } from '@nivo/pie'
import {Segment, Header} from 'semantic-ui-react';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import DashboardSidePanel from './DashboardSidePanel';
import {api} from '../../api';

class MainUserDashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            stats: {
                last: [],
                this: [],
                performances: [

                ]
            },
            y3pl: [

            ]
        }
    }

    componentDidMount() {
        this.setState({
            loading: false
        }, () => {
            api.system.dashboard().then(res => {
                this.setState({
                    stats: res.data.data
                }, () => {
                    this.setState({
                        loading: false
                    })
                })
            }).catch(err => {
                this.setState({
                    loading: false
                })
            })
        });
        api.system.y3plMessage().then(res => {
            this.setState({
                y3pl: res.data.data
            })
        })
    }
    
    render() {
        const {t, user, host} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={[
                            {isLink:false, to:null, tag: 'Dashboard', active: true, key: 1}
                        ]} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-9 col-sm-9 col-xs-12">
                        <div>
                            <Segment>
                                <SegmentRibbon label="Performance (By Shipments)" Buttons={null}/>
                                <div className="row">
                                    <div className="col-md-12 col-sm-12 col-xs-12 lara-chart">
                                        {!this.state.loading ? <ResponsiveLine
                                            data={_.isArray(this.state.stats.performances) ? this.state.stats.performances : []}
                                            margin={{
                                                "top": 50,
                                                "right": 110,
                                                "bottom": 50,
                                                "left": 60
                                            }}
                                            xScale={{
                                                "type": "point"
                                            }}
                                            yScale={{
                                                "type": "linear",
                                                "stacked": true,
                                                "min": "auto",
                                                "max": "auto"
                                            }}
                                            axisTop={null}
                                            axisRight={null}
                                            axisBottom={{
                                                "orient": "bottom",
                                                "tickSize": 5,
                                                "tickPadding": 5,
                                                "tickRotation": 0,
                                                "legend": "Period",
                                                "legendOffset": 36,
                                                "legendPosition": "middle"
                                            }}
                                            axisLeft={{
                                                "orient": "left",
                                                "tickSize": 5,
                                                "tickPadding": 5,
                                                "tickRotation": 0,
                                                "legend": "Shipment Count",
                                                "legendOffset": -40,
                                                "legendPosition": "middle"
                                            }}
                                            dotSize={10}
                                            dotColor="inherit:darker(0.3)"
                                            dotBorderWidth={2}
                                            dotBorderColor="#ffffff"
                                            enableDotLabel={true}
                                            dotLabel="y"
                                            dotLabelYOffset={-12}
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            legends={[
                                                {
                                                    "anchor": "bottom-right",
                                                    "direction": "column",
                                                    "justify": false,
                                                    "translateX": 100,
                                                    "translateY": 0,
                                                    "itemsSpacing": 0,
                                                    "itemDirection": "left-to-right",
                                                    "itemWidth": 80,
                                                    "itemHeight": 20,
                                                    "itemOpacity": 0.75,
                                                    "symbolSize": 12,
                                                    "symbolShape": "circle",
                                                    "symbolBorderColor": "rgba(0, 0, 0, .5)",
                                                    "effects": [
                                                        {
                                                            "on": "hover",
                                                            "style": {
                                                                "itemBackground": "rgba(0, 0, 0, .03)",
                                                                "itemOpacity": 1
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]}
                                        /> : null}
                                    </div>
                                </div>
                            </Segment>
                        </div>
                        <div className="lara-row-margin">
                            <Segment>
                                <SegmentRibbon label="Top Clients (Total Outbound Orders)" Buttons={null}/>
                                <div className="row">
                                    <div className="col-md-6 col-sm-6 col-xs-12 lara-chart">
                                        <Header as='h3'>This Month</Header>
                                        {!this.state.loading ? <ResponsivePieCanvas
                                            data={_.isArray(this.state.stats.this) ? this.state.stats.this : []}
                                            margin={{
                                                "top": -75,
                                                "right": 250,
                                                "bottom": 10,
                                                "left": 10
                                            }}
                                            pixelRatio={1}
                                            innerRadius={0.5}
                                            padAngle={0.7}
                                            cornerRadius={3}
                                            colors="paired"
                                            colorBy="id"
                                            borderColor="inherit:darker(0.6)"
                                            radialLabelsSkipAngle={10}
                                            radialLabelsTextXOffset={6}
                                            radialLabelsTextColor="#333333"
                                            radialLabelsLinkOffset={0}
                                            radialLabelsLinkDiagonalLength={16}
                                            radialLabelsLinkHorizontalLength={24}
                                            radialLabelsLinkStrokeWidth={1}
                                            radialLabelsLinkColor="inherit"
                                            slicesLabelsSkipAngle={10}
                                            slicesLabelsTextColor="#333333"
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            enableRadialLabels={false}
                                            defs={[
                                                {
                                                    "id": "dots",
                                                    "type": "patternDots",
                                                    "background": "inherit",
                                                    "color": "rgba(255, 255, 255, 0.3)",
                                                    "size": 4,
                                                    "padding": 1,
                                                    "stagger": true
                                                },
                                                {
                                                    "id": "lines",
                                                    "type": "patternLines",
                                                    "background": "inherit",
                                                    "color": "rgba(255, 255, 255, 0.3)",
                                                    "rotation": -45,
                                                    "lineWidth": 6,
                                                    "spacing": 10
                                                }
                                            ]}
                                            fill={[

                                            ]}
                                            legends={[
                                                {
                                                    "anchor": "right",
                                                    "direction": "column",
                                                    "translateX": 140,
                                                    "itemWidth": 60,
                                                    "itemHeight": 14,
                                                    "itemsSpacing": 2,
                                                    "symbolSize": 14,
                                                    "symbolShape": "circle"
                                                }
                                            ]}
                                        /> : null}
                                    </div>
                                    <div className="col-md-6 col-sm-6 col-xs-12 lara-chart">
                                        <Header as='h3'>Last Month</Header>
                                        {!this.state.loading ? <ResponsivePieCanvas
                                            data={_.isArray(this.state.stats.last) ? this.state.stats.last : []}
                                            margin={{
                                                "top": -75,
                                                "right": 250,
                                                "bottom": 10,
                                                "left": 10
                                            }}
                                            pixelRatio={1}
                                            innerRadius={0.5}
                                            padAngle={0.7}
                                            cornerRadius={3}
                                            colors="paired"
                                            colorBy="id"
                                            borderColor="inherit:darker(0.6)"
                                            radialLabelsSkipAngle={10}
                                            radialLabelsTextXOffset={6}
                                            radialLabelsTextColor="#333333"
                                            radialLabelsLinkOffset={0}
                                            radialLabelsLinkDiagonalLength={16}
                                            radialLabelsLinkHorizontalLength={24}
                                            radialLabelsLinkStrokeWidth={1}
                                            radialLabelsLinkColor="inherit"
                                            slicesLabelsSkipAngle={10}
                                            slicesLabelsTextColor="#333333"
                                            animate={true}
                                            motionStiffness={90}
                                            motionDamping={15}
                                            enableRadialLabels={false}
                                            defs={[
                                                {
                                                    "id": "dots",
                                                    "type": "patternDots",
                                                    "background": "inherit",
                                                    "color": "rgba(255, 255, 255, 0.3)",
                                                    "size": 4,
                                                    "padding": 1,
                                                    "stagger": true
                                                },
                                                {
                                                    "id": "lines",
                                                    "type": "patternLines",
                                                    "background": "inherit",
                                                    "color": "rgba(255, 255, 255, 0.3)",
                                                    "rotation": -45,
                                                    "lineWidth": 6,
                                                    "spacing": 10
                                                }
                                            ]}
                                            fill={[

                                            ]}
                                            legends={[
                                                {
                                                    "anchor": "right",
                                                    "direction": "column",
                                                    "translateX": 140,
                                                    "itemWidth": 60,
                                                    "itemHeight": 14,
                                                    "itemsSpacing": 2,
                                                    "symbolSize": 14,
                                                    "symbolShape": "circle"
                                                }
                                            ]}
                                        /> : null}
                                    </div>
                                </div>
                            </Segment>
                        </div>
                    </div>
                    <div className="col-md-3 col-sm-3 col-xs-12">
                        <DashboardSidePanel
                            user={user}
                            host={host}
                            messages={this.state.y3pl}
                        />
                    </div>
                </div>
            </div>

        )
    }
}

MainUserDashboard.propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    host: PropTypes.string.isRequired
};


export default MainUserDashboard