import React from 'react';
import PropTypes from 'prop-types';
import SegmentRibbon from "./SegmentRibbon";
import {List, Segment} from "semantic-ui-react";
import _ from "lodash";

function shouldShowInMeta(meta = {}, field = "") {
    if (field === 'commerce_cloud_ftp') {
        return false;
    }
    if (_.isString(meta[field]) || _.isBoolean(meta[field]) || _.isInteger(meta[field])) {
        return true;
    }
    return false;
}

const OrderMetaSegment = ({model}) => (
    <Segment>
        <SegmentRibbon label={'Meta Fields'} Buttons={null}/>
        <List>
            {Object.keys(model.meta).map(field => {
                return shouldShowInMeta(model.meta, field) ? <List.Item key={field}>
                    <List.Header>{_.upperCase(field)}</List.Header>
                    {_.isBoolean(model.meta[field]) ? <span>
                                            {model.meta[field] ? 'Yes' : 'No'}
                                        </span> : model.meta[field]}
                </List.Item> : null
            })}
        </List>
    </Segment>
)

OrderMetaSegment.propTypes = {
    model: PropTypes.shape({
        meta: PropTypes.any
    })
};


export default OrderMetaSegment
