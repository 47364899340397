import React, { Component } from 'react'
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { bindActionCreators} from 'redux'
import _ from 'lodash';
import { connect } from 'react-redux'
import {Table, Dropdown,Modal, Form, Header, Message, Icon} from 'semantic-ui-react';
import FormError from '../../includes/messages/422';
import {api} from '../../../api';
import {
    fetchById
} from '../../../actions/inventory';
import Lara from '../../../lara';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

const noLot = "_SystemNoLotNumber";
const noLicense = "_SystemNoLicense";

class InventoryBinLocationPane extends Component {
    constructor(props) {
        super(props);
        this.state = {
            errors: [],
            loading: false,
            quantityModal: false,
            mode: null,
            moveModel: {
                client_id: null,
                inventory_id: null,
                is_from_quarantine: true,
                is_to_quarantine: false,
                from_lp: null,
                to_lp: null,
                qty: 0,
                lotId: null
            },
            adjustModel: {
                client_id: null,
                inventory_id: null,
                license: null,
                lotId: "",
                newLot: {
                    lot: null,
                    expire: null
                },
                qty: 0,
                quarantine: false,
                reference: "",
                is_disposal: false
            }
        }
    }

    onAdjustbackToPristineClick = (i) => {
        const {client_id} = this.props.model;
        const {inventory_id, license, total, lot_id} = i;
        this.setState({
            quantityModal: true,
            mode: 'ToPristine',
            moveModel: {
                client_id: i.client_id ? i.client_id : client_id,
                inventory_id: inventory_id,
                is_from_quarantine: true,
                is_to_quarantine: false,
                from_lp: license,
                to_lp: license,
                qty: total,
                lotId: lot_id
            }
        })
    }

    onMoveQuantityClick = (i) => {
        const {client_id} = this.props.model;
        const {inventory_id, license, total, lot_id} = i;
        this.setState({
            quantityModal: true,
            mode: 'MoveBetweenLp',
            moveModel: {
                client_id: i.client_id ? i.client_id : client_id,
                inventory_id: inventory_id,
                is_from_quarantine: false,
                is_to_quarantine: false,
                from_lp: license,
                to_lp: "",
                qty: total,
                lotId: lot_id
            }
        })
    }

    onDisposalClick = (i) => {
        const {client_id} = this.props.model;
        const {inventory_id, license, total, lot_id, lot_number, expire_date} = i;
        this.setState({
            quantityModal: true,
            mode: 'Disposal',
            adjustModel: {
                client_id: i.client_id ? i.client_id : client_id,
                inventory_id: inventory_id,
                license: license,
                lotId: lot_id,
                newLot: {
                    lot: lot_number,
                    expire: expire_date
                },
                qty: total * -1,
                quarantine: true,
                reference: "",
                is_disposal: true
            }
        })
    }

    onAdjustClick = (i) => {
        const {client_id} = this.props.model;
        const {inventory_id, license, lot_id, lot_number, expire_date, quarantine} = i;
        this.setState({
            quantityModal: true,
            mode: 'Adjustment',
            adjustModel: {
                client_id: i.client_id ? i.client_id : client_id,
                inventory_id: inventory_id,
                license: license,
                lotId: lot_id,
                newLot: {
                    lot: lot_number,
                    expire: expire_date
                },
                qty: 0,
                quarantine: quarantine,
                reference: "",
                is_disposal: false
            }
        })
    }

    onZeroQtyAdjustment = () => {
        const {id, client_id} = this.props.model;
        this.setState({
            quantityModal: true,
            mode: 'Adjustment',
            adjustModel: {
                client_id: client_id,
                inventory_id: id,
                license: null,
                lotId: null,
                newLot: {
                    lot: null,
                    expire: null
                },
                qty: 0,
                quarantine: false,
                reference: "",
                is_disposal: false
            }
        })
    }

    onSubmitAdjustmentClick = () => {
        const {mode, moveModel, adjustModel} = this.state;

        if (mode === 'ToPristine' || mode === 'MoveBetweenLp') {
            if (!moveModel.inventory_id) {
                return;
            }
            if (parseInt(moveModel.qty,10) <= 0) {
                return;
            }
            this.setState({loading: true, errors: []});
            api.inventories.move(moveModel).then(res => {
                this.setState({
                    loading: false,
                    quantityModal: false,
                    mode: null,
                    moveModel: {
                        client_id: null,
                        inventory_id: null,
                        is_from_quarantine: true,
                        is_to_quarantine: false,
                        from_lp: null,
                        to_lp: null,
                        qty: 0,
                        lotId: null
                    }
                }, () => {
                    this.props.fetchById(moveModel.inventory_id);
                })
            }).catch(err => {
                this.handleAxiosError(err);
            })
        }
        if (mode === 'Disposal' || mode === 'Adjustment') {
            if (!adjustModel.inventory_id) {
                return;
            }
            this.setState({loading: true, errors: []});
            api.inventories.adjust(adjustModel).then(res => {
                this.setState({
                    loading: false,
                    quantityModal: false,
                    mode: null,
                    adjustModel: {
                        client_id: null,
                        inventory_id: null,
                        license: null,
                        lotId: "",
                        newLot: {
                            lot: null,
                            expire: null
                        },
                        qty: 0,
                        quarantine: false,
                        reference: "",
                        is_disposal: false
                    }
                }, () => {
                    this.props.fetchById(adjustModel.inventory_id);
                })
            }).catch(err => {
                this.handleAxiosError(err);
            })
        }
    }

    handleAxiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                errors: [errors.message],
                loading: false
            });
        }
    }

    render() {
        const{t,requireLot, locations, user} = this.props;
        const {moveModel, adjustModel,  mode} = this.state;
        return (
            <div>
                <Table compact>
                    <Table.Header>
                        <Table.Row>
                            <Table.HeaderCell>
                                {user.is_main_user ? <span onClick={() => {
                                    this.onZeroQtyAdjustment()
                                }} className="lara-clickable lara-editable"><Icon name='wrench' /></span> : null} {t("LicensePlate")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Location")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("LocationType")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("LocationCategory")}
                            </Table.HeaderCell>
                            <Table.HeaderCell>
                                {t("Total")}
                            </Table.HeaderCell>
                            {requireLot ? <Table.HeaderCell>
                                {t("Lot Exp")}
                            </Table.HeaderCell> : null}
                            {user.is_main_user ? <Table.HeaderCell>
                                {t("Actions")}
                            </Table.HeaderCell> : null}
                        </Table.Row>
                    </Table.Header>
                    <Table.Body>
                        {locations.map(i => {
                            return (
                                <Table.Row key={_.uniqueId()}>
                                    <Table.Cell>
                                        {i.license !== noLicense ? i.license : 'NA'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.locations.length > 0 ? i.locations[0].location : 'NA'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.locations.length > 0 ? i.locations[0].type : 'NA'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.locations.length > 0 ? i.locations[0].billing_category : 'NA'}
                                    </Table.Cell>
                                    <Table.Cell>
                                        {i.quarantine ?
                                            <span className="lara-quarantined">{i.total}</span> :
                                            <span className="lara-regular">{i.total}</span>}
                                    </Table.Cell>
                                    {requireLot ? <Table.Cell>
                                        {i.lot_number !== noLot ? i.lot_number : 'NA'} [{i.lot_number !== noLot ? i.expire_date : 'NA'}]
                                    </Table.Cell> : null}
                                    {user.is_main_user ?  <Table.Cell>
                                        <Dropdown icon='wrench' loading={this.state.loading} disabled={this.state.loading}>
                                            <Dropdown.Menu>
                                                {!i.quarantine ? <Dropdown.Item
                                                    onClick={() => {
                                                        this.onMoveQuantityClick(i);
                                                    }}
                                                    icon='move'
                                                    text={t('MoveBetweenLp')} /> : null}
                                                {!i.quarantine ? <Dropdown.Item
                                                    onClick={() => {
                                                        this.onAdjustClick(i);
                                                    }}
                                                    icon="adjust"
                                                    text={t('AdjustQty')} /> : null}
                                                {i.quarantine ? <Dropdown.Item
                                                    onClick={() => {
                                                        this.onAdjustbackToPristineClick(i);
                                                    }}
                                                    icon='box'
                                                    text={t('ConvertToPristine')} /> : null}
                                                {i.quarantine ? <Dropdown.Item
                                                    onClick={() => {
                                                        this.onDisposalClick(i);
                                                    }}
                                                    icon='trash alternate'
                                                    text={t('Disposal')} /> : null}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </Table.Cell> : null}
                                </Table.Row>
                            )
                        })}
                    </Table.Body>
                </Table>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({quantityModal: false})
                    }}
                    size='mini'
                    closeOnEscape={true} open={this.state.quantityModal}>
                    <Header icon='pencil' content={null} />
                    <Modal.Content>
                        {!_.isEmpty(this.state.errors) ? (
                            <FormError errors={this.state.errors} />
                        ) : null}
                        {mode === 'ToPristine' || mode === 'MoveBetweenLp' ? <Form size='mini'>
                            {mode === 'ToPristine' ? <Message>
                                <Message.Header>Convert Quarantined to Pristine</Message.Header>
                            </Message> : null}
                            {mode === 'MoveBetweenLp' ? <Message>
                                <Message.Header>Move Quantities between Licenses</Message.Header>
                            </Message> : null}
                            <Form.Input label={t('FromLp')} value={moveModel.from_lp}
                                onChange={(e, {value}) => {
                                    return null
                                }} fluid disabled={true} />
                            <Form.Input label={t('ToLp')} value={moveModel.to_lp}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        moveModel: update(moveModel, {
                                            to_lp: {$set: value}
                                        })
                                    })
                                }} fluid />
                            <Form.Input label={t('Quantity')} type='number' value={moveModel.qty}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        moveModel: update(moveModel, {
                                            qty: {$set: value}
                                        })
                                    })
                                }} fluid />
                            <PrimaryButton
                                size='mini'
                                float='left'
                                label={t('Save')}
                                onClick={() => {
                                    this.onSubmitAdjustmentClick();
                                }}
                                loading={this.state.loading}
                            />
                            <div className="row">
                            <div className="lara-row-margin"></div>
                            </div>
                        </Form> : null}
                        {mode === 'Disposal' || mode === 'Adjustment' ? <div>
                        <Message>
                            <Message.Header>Quantity Adjustment</Message.Header>
                            <p>Use positive or negative integer to adjust inventory quantites in this license.</p>
                        </Message>
                        <Form size='mini'>
                            <Form.Input label={t('AdjustmentRef')} value={adjustModel.reference}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        adjustModel: update(adjustModel, {
                                            reference: {$set: value}
                                        })
                                    })
                            }} fluid />
                            <Form.Input label={t('QuantityChange')} type='number' value={adjustModel.qty}
                                onChange={(e, {value}) => {
                                    this.setState({
                                        adjustModel: update(adjustModel, {
                                            qty: {$set: value}
                                        })
                                    })
                            }} fluid />
                            <Form.Checkbox
                                label={t('Quarantine')}
                                name="quarantine"
                                onChange={(e,data) => { this.setState({
                                    adjustModel: update(adjustModel, {
                                        quarantine: {$set: data.checked}
                                    })
                                }) }}
                                checked={adjustModel.quarantine}
                            />
                            <PrimaryButton
                                size='mini'
                                float='left'
                                label={t('Save')}
                                onClick={() => {
                                    this.onSubmitAdjustmentClick();
                                }}
                                loading={this.state.loading}
                            />
                            <div className="row">
                            <div className="lara-row-margin"></div>
                            </div>
                        </Form></div> : null}
                    </Modal.Content>
                </Modal>
            </div>
        )
    }
}

InventoryBinLocationPane.propTypes = {
    locations: PropTypes.array.isRequired,
    model: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    fetchById: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById
    }, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(InventoryBinLocationPane)
