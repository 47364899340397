import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import moment from "moment";
import _ from "lodash";
import { connect } from 'react-redux';
import update from 'immutability-helper';
import {
    Segment,
    Loader,
    Dimmer,
    Input,
    Modal,
    Header,
    Form,
    Icon,
    Table,
    Message
} from "semantic-ui-react";
import PropTypes from 'prop-types';
import {api} from '../../../api';
import SegmentRibbon from "../../includes/partials/SegmentRibbon";
import PrimaryButton from "../../includes/buttons/PrimaryButton";
import PDFDisplayCanvas from '../../includes/partials/PDFDisplayCanvas';

class FlexIntegrationSegment extends Component {
    constructor(props) {
        super(props);
        this.state = {
            model: {
                initialized: false,
                meta: {
                    
                },
                packages: [

                ]
            },
            modal: false,
            base64String: "",
            pdfModal: false,
            loading: false,
            currency: "USD",
            createdBy: "",
            customerId: "",
            doShowFrom: false,
            doShowTo: true,
            validations: [
                
            ]
        }
    }

    componentDidMount() {
        const {order} = this.props.model;
        if (_.isObject(order)) {
            api.flex.getClientCurrency(order.client_id).then(res => {
                const {data} =  res.data;
                this.setState({
                    customerId: data.customerId,
                    createdBy: data.userEmail,
                    currency: data.currency
                })
            })
        }
    }

    onStartClick = () => {
        const {model, ownAddress, me} = this.props;
        const {shipping_addr, courier, shipment_lines} = model;
        const meta = {
            //supplierId: "TNT",
            commentsToOperator: "",
            quoteType: "E",
            pickUpDate: moment().format("YYYY-MM-DD HH:mm:ss"),
            pickUpClose: moment().endOf('day').add(-7,'hours').format("YYYY-MM-DD HH:mm:ss"),
            pickUpFrom: {
                CompanyName: ownAddress.name,
                Address1: ownAddress.address,
                Address2: "",
                Zip: ownAddress.postal ? ownAddress.postal : "",
                City: ownAddress.city,
                ManualCity: "",
                State: ownAddress.state ? ownAddress.state : "",
                Country: ownAddress.country,
                Att: me.name,
                Phone: ownAddress.phone,
                Email: me.email,
                BookingAddressType: "Shipper"
            },
            shipTo: {
                CompanyName: shipping_addr.company ? shipping_addr.company : shipping_addr.contact ? shipping_addr.contact : "",
                Address1: shipping_addr.street1 ? shipping_addr.street1 : "",
                Address2: `${shipping_addr.street2 ? shipping_addr.street2 : ""}${shipping_addr.street3 ? " "+shipping_addr.street3:""}`,
                Zip: shipping_addr.postal_code ? shipping_addr.postal_code : "",
                City: shipping_addr.city,
                ManualCity: "",
                State: shipping_addr.state_code && (shipping_addr.state_code !== 'NA') ? shipping_addr.state_code : "",
                Country: shipping_addr.country_code ? shipping_addr.country_code : "",
                Att: shipping_addr.contact ? shipping_addr.contact : "Receiving",
                Phone: shipping_addr.phone ? shipping_addr.phone : ownAddress.phone,
                Email: me.email,
                BookingAddressType: "Delivery"
            },
            incoCode: "DAP",
            transportMode: "CUR",
            reference1: "", 
            reference2: "",
            serviceCode: courier.flex_service,
            serviceName: "_NA",
            supplierServiceId: "_NA",
            goodDescription: "Merchandise",
            insurance: false,
            insuranceCurrency: "USD",
            insuranceCommodity: "",
            insuranceValue: "",
            isDocument: false,
            isReceiverPays: courier.payment_method === 'prepaid' ? false : true,
            supplierAccount: courier.payment_method === 'prepaid' ? "" : courier.account,
            senderReference: "NA",
            receiverReference: "NA",
            labelType: "PDF",
            labelSize: "A4"
        }
        
        const packages = [];
        if (model.packed) {
            let totalValue = 0;
            _.each(shipment_lines, l => {
                const {international} = l.inventory;
                if (_.isObject(international)) {
                    if (parseFloat(international.custom_value) > 0) {
                        totalValue = totalValue + (parseFloat(international.custom_value) * l.quantity);
                    }
                }
            })
            
            
            const totalPackages = model.containers.length;
            if (totalPackages > 0) {
                const valuePerPackage = Math.ceil(totalValue / totalPackages);
                model.containers.map((v) => {
                    packages.push({
                        _id: _.uniqueId(),
                        length: v.length,
                        width: v.width,
                        height: v.height,
                        weight: v.weight,
                        commercialValue: valuePerPackage,
                        currencyCommercialValue: this.state.currency,
                        unitType: "CLL",
                        count: 1
                    })
                })
            }
        }

        this.setState({
            model: {
                initialized: true,
                meta: meta,
                packages: packages
            },
            modal: true,
            loading: false
        })
    }

    onModelMetaChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                meta: {
                    [field]: {$set: value}
                }
            })
        })
    }

    onMetaAddressChange = (type, field, value) => {
        this.setState({
            model: update(this.state.model, {
                meta: {
                    [type]: {
                        [field]: {$set: value}
                    }
                }
            })
        }) 
    }

    onModelSinglePackageUpdate = (field, pack, value) => {
        const packIndex = _.findIndex(this.state.model.packages, p => {
            return p._id === pack._id;
        })
        if (packIndex > -1) {
            this.setState({
                model: update(this.state.model, {
                    packages: {
                        [packIndex]: {
                            [field]: {$set: value}
                        }
                    }
                })
            })
        }
    }

    onAddPackageToModelPackages = () => {
        this.setState({
            model: update(this.state.model, {
                packages: {
                    $push: [{
                        _id: _.uniqueId(),
                        length: 1,
                        width: 1,
                        height: 1,
                        weight: 1,
                        commercialValue: 0,
                        currencyCommercialValue: this.state.currency,
                        unitType: "CLL"
                    }] 
                }
            })
        })
    }

    onRemovePackageFromModelPackages = (pack) => {
        const packIndex = _.findIndex(this.state.model.packages, p => {
            return p._id === pack._id;
        })

        if (packIndex > -1) {
            this.setState({
                model: update(this.state.model, {
                    packages: {
                        $splice: [[packIndex, 1]]
                    }
                })
            })
        } 
    }

    onEndClick = () => {
        this.setState({
            modal: false
        })
    }

    validateInput = ({meta, packages}) => {
        const errors = [];
        if (_.isEmpty(packages)) {
            errors.push("At least one package is required");
        }
        return errors;
    }

    onSubmitLabelRequest = () => {
        const {model} = this.state;
        // console.log(model);
        // return null;
        const validates = this.validateInput(model);
        if (validates.length > 0) {
            this.setState({
                validations: validates
            })
            return null;
        }

        this.setState({
            loading: true,
            validations: []
        });

        api.flex.requestLabel(this.props.model.id, model).then(res => {
            this.setState({
                loading: false
            });
            const {attachments, base64, bookingId, tracking} = res.data.data;
            this.props.shipmentFlexShipped(tracking,bookingId,attachments);
            if (base64) {
                this.setState({
                    modal: false,
                    pdfModal: true,
                    base64String: base64
                })
            }
        }).catch(err => {
            let mess = "An unknown error was returned from Flexbook server.";
            if (_.isObject(err.response.data)) {
                mess = err.response.data.message
            }
            this.setState({
                loading: false,
                validations: [mess]
            });
        })
    }
    
    render() {
        const {fb} = this.props;
        const {model, validations} = this.state;
        return (
            <Segment>
                <SegmentRibbon label="Flex Shipping" Buttons={() => {
                    return <PrimaryButton 
                        size='mini'
                        float='right'
                        label="Ship"
                        onClick={() => {this.onStartClick()}}
                        loading={false}
                    />
                }} />
                <Dimmer inverted active={this.state.loading}>
                    <Loader />
                </Dimmer>
                {this.props.model.tracking.status && this.props.model.tracking.status !== "BT_Fulfilled" ? <Message info>
                    <Message.Header>Label Created</Message.Header>
                    <p>
                        A booking has been confirmed by Flex Book (Booking # {this.props.model.tracking.status}). You can retrieve this label from the attachments below.
                    </p>
                </Message> : null}
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.onEndClick()
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.modal}>
                    <Header icon='shipping' content={null} />
                    <Modal.Content>
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                {model.initialized ? <Form size="mini">
                                    <Header as="h4">Courier Service</Header>
                                    <Form.Group widths="equal">
                                        <Form.Select
                                            search
                                            required={true}
                                            label="Service Code"
                                            options={fb.SupplierId ? fb.SupplierId : []}
                                            value={model.meta.serviceCode}
                                            name="serviceCode"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('serviceCode', value)
                                            }}
                                        />
                                        <Form.Select
                                            search
                                            label="Service Name"
                                            options={fb.ServiceName ? fb.ServiceName : []}
                                            value={model.meta.serviceName}
                                            name="serviceName"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('serviceName', value)
                                            }}
                                        />
                                        <Form.Select
                                            search
                                            label="Supplier Service ID"
                                            options={fb.SupplierServiceId ? fb.SupplierServiceId : []}
                                            value={model.meta.supplierServiceId}
                                            name="supplierServiceId"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('supplierServiceId', value)
                                            }}
                                        />
                                        <Form.Select
                                            required={true}
                                            label="Quote Type"
                                            options={fb.QuoteType ? fb.QuoteType : []}
                                            value={model.meta.quoteType}
                                            name="quoteType"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('quoteType', value)
                                            }}
                                        />
                                        <Form.Select
                                            required={true}
                                            label="Incoterm Code"
                                            options={fb.IncoCode ? fb.IncoCode : []}
                                            value={model.meta.incoCode}
                                            name="incoCode"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('incoCode', value)
                                            }}
                                        />
                                        <Form.Select
                                            required={true}
                                            label="Transportation Mode"
                                            options={fb.TransportMode ? fb.TransportMode : []}
                                            value={model.meta.transportMode}
                                            name="transportMode"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('transportMode', value)
                                            }}
                                        />
                                    </Form.Group>
                                    <Header as="h4">Ship From <span className="lara-editable lara-clickable" onClick={() => {
                                        this.setState({
                                            doShowFrom: !this.state.doShowFrom
                                        })
                                    }}><Icon name='angle down' /></span> </Header>
                                    <div className={this.state.doShowFrom ? null : "lara-hidden"}>
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                label="Company Name"
                                                value={model.meta.pickUpFrom.CompanyName}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="CompanyName"
                                            />
                                            <Form.Input
                                                label="Attention"
                                                value={model.meta.pickUpFrom.Att}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Att"
                                            />
                                            <Form.Input
                                                label="Address 1"
                                                value={model.meta.pickUpFrom.Address1}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Address1"
                                            />
                                            <Form.Input
                                                label="Address 2"
                                                value={model.meta.pickUpFrom.Address2}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Address2"
                                            />
                                            <Form.Input
                                                label="Zip Code"
                                                value={model.meta.pickUpFrom.Zip}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Zip"
                                            />
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                label="City"
                                                value={model.meta.pickUpFrom.City}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="City"
                                            />
                                            <Form.Input
                                                label="Manual City"
                                                value={model.meta.pickUpFrom.ManualCity}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="ManualCity"
                                            />
                                            <Form.Input
                                                label="State"
                                                value={model.meta.pickUpFrom.State}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="State"
                                            />
                                            <Form.Input
                                                label="Country Code"
                                                value={model.meta.pickUpFrom.Country}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Country"
                                            />
                                            <Form.Input
                                                label="Email"
                                                value={model.meta.pickUpFrom.Email}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Email"
                                            />
                                            <Form.Input
                                                label="Phone"
                                                value={model.meta.pickUpFrom.Phone}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('pickUpFrom', e.target.name, e.target.value)
                                                }}
                                                name="Phone"
                                            />
                                        </Form.Group>
                                    </div>
                                    <Header as="h4">Ship To <span className="lara-editable lara-clickable" onClick={() => {
                                        this.setState({
                                            doShowTo: !this.state.doShowTo
                                        })
                                    }}><Icon name='angle down' /></span></Header>
                                    <div className={this.state.doShowTo ? null : "lara-hidden"}>
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                label="Company Name"
                                                value={model.meta.shipTo.CompanyName}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="CompanyName"
                                            />
                                            <Form.Input
                                                label="Attention"
                                                value={model.meta.shipTo.Att}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Att"
                                            />
                                            <Form.Input
                                                label="Address 1"
                                                value={model.meta.shipTo.Address1}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Address1"
                                            />
                                            <Form.Input
                                                label="Address 2"
                                                value={model.meta.shipTo.Address2}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Address2"
                                            />
                                            <Form.Input
                                                label="Zip Code"
                                                value={model.meta.shipTo.Zip}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Zip"
                                            />
                                        </Form.Group>
                                        <Form.Group widths='equal'>
                                            <Form.Input
                                                label="City"
                                                value={model.meta.shipTo.City}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="City"
                                            />
                                            <Form.Input
                                                label="Manual City"
                                                value={model.meta.shipTo.ManualCity}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="ManualCity"
                                            />
                                            <Form.Input
                                                label="State"
                                                value={model.meta.shipTo.State}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="State"
                                            />
                                            <Form.Input
                                                label="Country Code"
                                                value={model.meta.shipTo.Country ? model.meta.shipTo.Country : ""}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Country"
                                            />
                                            <Form.Input
                                                label="Email"
                                                value={model.meta.shipTo.Email}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Email"
                                            />
                                            <Form.Input
                                                label="Phone"
                                                value={model.meta.shipTo.Phone}
                                                onChange={(e) => {
                                                    this.onMetaAddressChange('shipTo', e.target.name, e.target.value)
                                                }}
                                                name="Phone"
                                            />
                                        </Form.Group>
                                    </div>
                                    <Header as="h4">Cartons</Header>
                                    <Table compact>
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <span className="lara-editable lara-clickable" onClick={() => {
                                                            this.onAddPackageToModelPackages()
                                                    }}><Icon name='plus square' /></span>
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Length (CM)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Width (CM)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Height (CM)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Weight (KG)
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Value
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Currency
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Type
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    # Of Carton
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {model.packages.map((pack, index) =>  {
                                                return <Table.Row key={pack._id}>
                                                    <Table.Cell>
                                                        <span className="lara-editable lara-clickable" onClick={() => {
                                                            this.onRemovePackageFromModelPackages(pack)
                                                        }}><Icon name='trash' /></span>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.length} type='number' onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('length', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.width} type='number' onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('width', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.height} type='number' onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('height', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.weight} type='number' onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('weight', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.commercialValue} type='number' onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('commercialValue', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.currencyCommercialValue} onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('currencyCommercialValue', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.unitType} onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('unitType', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        <Input size='mini' value={pack.count} onChange={(e, {value}) => {
                                                            this.onModelSinglePackageUpdate('count', pack, value)
                                                        }}/>
                                                    </Table.Cell>
                                                </Table.Row>
                                            })}
                                        </Table.Body>
                                    </Table>
                                    <Header as="h4">Additional Information</Header>
                                    <Form.Group widths="equal">
                                        <Form.Input
                                            label="Goods Description"
                                            value={model.meta.goodDescription}
                                            name="goodDescription"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('goodDescription', value)
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group widths="equal">
                                        <Form.Input
                                            label="Operatior Comment"
                                            value={model.meta.commentsToOperator}
                                            name="commentsToOperator"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('commentsToOperator', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Reference 1"
                                            value={model.meta.reference1}
                                            name="reference1"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('reference1', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Reference 2"
                                            value={model.meta.reference2}
                                            name="reference2"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('reference2', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Sender Reference"
                                            value={model.meta.senderReference}
                                            name="senderReference"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('senderReference', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Receiver Reference"
                                            value={model.meta.receiverReference}
                                            name="receiverReference"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('receiverReference', value)
                                            }}
                                        />
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Checkbox
                                            label="Document Only"
                                            name="isDocument"
                                            onChange={(e,data) => { this.onModelMetaChange('isDocument', data.checked) }}
                                            checked={model.isDocument}
                                        />
                                        <Form.Checkbox
                                            label="Insurance"
                                            name="insurance"
                                            onChange={(e,data) => { this.onModelMetaChange('insurance', data.checked) }}
                                            checked={model.insurance}
                                        />
                                        <Form.Checkbox
                                            label="Bill To Receiver"
                                            name="isReceiverPays"
                                            onChange={(e,data) => { this.onModelMetaChange('isReceiverPays', data.checked) }}
                                            checked={model.isReceiverPays}
                                        />
                                    </Form.Group>
                                    {this.state.model.meta.insurance ? <Form.Group widths="equal">
                                        <Form.Input
                                            label="Insurance Commodity"
                                            name="insuranceCommodity"
                                            value={model.meta.insuranceCommodity}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('insuranceCommodity', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Insurance Value"
                                            name="insuranceValue"
                                            value={model.meta.insuranceValue}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('insuranceValue', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Insurance Currency"
                                            name="insuranceCurrency"
                                            value={model.meta.insuranceCurrency}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('insuranceCurrency', value)
                                            }}
                                        />
                                    </Form.Group> : null}
                                    {this.state.model.meta.isReceiverPays ? <Form.Group widths="equal">
                                        <Form.Input
                                            label="Collect Account"
                                            name="supplierAccount"
                                            value={model.meta.supplierAccount}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('supplierAccount', value)
                                            }}
                                        />
                                    </Form.Group> : null}
                                    <Form.Group widths="equal">
                                        <Form.Select
                                            required={true}
                                            label="Label Type"
                                            options={fb.LabelType ? fb.LabelType : []}
                                            value={model.meta.labelType}
                                            name="labelType"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('labelType', value)
                                            }}
                                        />
                                        <Form.Select
                                            required={true}
                                            label="Label Size"
                                            options={fb.LabelSize ? fb.LabelSize : []}
                                            value={model.meta.labelSize}
                                            name="labelSize"
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('labelSize', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Ready Time (YYYY-MM-DD HH-mm-ss)"
                                            name="pickUpDate"
                                            value={model.meta.pickUpDate}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('pickUpDate', value)
                                            }}
                                        />
                                        <Form.Input
                                            label="Close Time (YYYY-MM-DD HH-mm-ss)"
                                            name="pickUpClose"
                                            value={model.meta.pickUpClose}
                                            onChange={(e, {value}) => {
                                                this.onModelMetaChange('pickUpClose', value)
                                            }}
                                        />
                                    </Form.Group>
                                </Form> : null}
                            </div>
                            
                            {validations.length > 0 ? <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                                <Message error>
                                    <Message.Header>Error(s)</Message.Header>
                                    {validations.map((val,i) => {
                                        return <Message.Item key={i}>
                                            {val}
                                        </Message.Item>
                                    })}
                                </Message>
                            </div> : null}
                            <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                                <PrimaryButton 
                                    size='mini'
                                    float='right'
                                    label="Get Label"
                                    onClick={() => {
                                        this.onSubmitLabelRequest();
                                    }}
                                    loading={this.state.loading}
                                />
                            </div>
                        </div>
                    </Modal.Content>
                </Modal>
                <Modal
                    closeOnDimmerClick={false}
                    closeIcon
                    onClose={() => {
                        this.setState({pdfModal: false})
                    }}
                    size='large'
                    closeOnEscape={true} open={this.state.pdfModal}>
                    <Header icon='file pdf outline' content={null} />
                    <Modal.Content>
                        <PDFDisplayCanvas
                            pdf={this.state.base64String}
                        />
                    </Modal.Content>
                </Modal>
            </Segment>
        )
    }
}

FlexIntegrationSegment.propTypes = {
    model: PropTypes.shape({
        id: PropTypes.number.isRequired,
        tracking: PropTypes.object.isRequired
    }),
    fb: PropTypes.shape({
        ServiceName: PropTypes.array.isRequired,
        SupplierServiceId: PropTypes.array.isRequired,
        QuoteType: PropTypes.array.isRequired,
        IncoCode: PropTypes.array.isRequired,
        TransportMode: PropTypes.array.isRequired
    }),
    ownAddress: PropTypes.object.isRequired,
    me: PropTypes.object.isRequired,
    shipmentFlexShipped: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        fb: state.system.fb,
        ownAddress: state.system.preference,
        me: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({}, dispatch)
}

export default connect(mapStateToProps, mapDispatchToProps)(FlexIntegrationSegment)