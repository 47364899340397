import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Icon} from 'semantic-ui-react';
import SegmentRibbon from '../../includes/partials/SegmentRibbon';
import PageBreadCrub from '../../includes/partials/PageBreadCrub';
import qs from 'qs';
import update from 'immutability-helper';
import {
    fetchItemPaginatedList,
    setItemModel
} from '../../../actions/billing';
import OrderBy from '../../includes/tables/OrderBy';
import SearchBar from '../../includes/tables/SearchBar';
import Pagination from '../../includes/tables/Pagination';
import FilterBy from '../../includes/tables/FilterBy';
import AlertComponent from '../../includes/messages/alert';
import Lara from '../../../lara';
import LaraPagination from '../../../laraPagination';
import ModalWrapper from '../../includes/partials/ModalWrapper';
import ItemInlineForm from './ItemInlineForm';
import PrimaryButton from '../../includes/buttons/PrimaryButton';

//Lara Button Updated
class BillingItemList extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Billing', active: false, key: 1},
                {isLink:false, to:null, tag: 'Item', active: true, key:2}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            formModal: false,
            recordIndex: -1
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchItemPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            const errors = Lara.axiosError(err, this.props.t);
            if (errors.http_code === 422) {
                this.setState({
                    errors: errors.message,
                    loading: false
                });
            } else {
                this.setState({
                    loading: false
                });
                Lara.axiosAlert(<AlertComponent 
                    support={errors.support}
                    message={errors.message}
                    reference={errors.reference}
                    t={this.props.t}
                />);
            }
            this.setState({loading: false});
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/billing/items/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     *
     */
    onFilterSelectChange = (field, value) => {
        this.setState({
            routingQuery: update(this.state.routingQuery, {
                filter_by: {
                    [field]: {$set: value.join()}
                }
            })
        }, () => {
            this.routeToNewQuery(this.state.routingQuery);
        });
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.inventories(true);
            defaultQuery.search = this.state.search;
            this.routeToNewQuery(defaultQuery);
        }
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    onEditRecordClick = (record, index) => {
        this.props.setItemModel(record);
        this.setState({
            recordIndex: index,
            formModal: true
        })
    }

    render() {
        const {routingQuery, search} = this.state;
        const {t, list} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        const filterBy = _.isObject(routingQuery.filter_by) ? routingQuery.filter_by : {
            type: "",
            is_active: ""
        };
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('Item')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Create')}
                                        onClick={() => {
                                            this.onEditRecordClick(null, -1);
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                    placeholder='Search by item name...'
                                    onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                    search={search}
                                    onSearchChange={(search) => {this.setState({search})}}
                                    onPredefinedRouteSelected={() => null}
                                    options={[]}
                                    havePreferenceSelect={false}
                                    hasPerPageSelector={routingQuery.per_page}
                                    onPerPageSelcted={(perPage) => {
                                        this.onPerPageChanged(perPage);
                                    }}
                                />

                            <div className="row">
                                <FilterBy
                                    t={t}
                                    label={t('Status')}
                                    options={[
                                        {value: '1', text: 'Active'},
                                        {value: '0', text: 'Disabled'},
                                    ]}
                                    name="is_active"
                                    currentValueString={filterBy.is_active}
                                    onFilterSelectChange={(name, value) => {this.onFilterSelectChange(name, value)}}
                                />
                            </div>
                        </Segment>
                        
                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('Item')} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='Name'
                                                        routingOrderName='name'
                                                        currentOrder={orderBy.name}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("UOM")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("ReportInternal")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("AccountingClass")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("AccountingTaxCode")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("Status")}
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map((i,index) => {
                                                return (<Table.Row key={i.id}>
                                                    <Table.Cell>
                                                        <span onClick={() => {
                                                            this.onEditRecordClick(i, index);
                                                        }}
                                                            className="lara-editable lara-clickable">
                                                            {i.name}
                                                        </span>
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.uom}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.internal ? <Icon name="check" color='green' /> : <Icon name="times" />}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.accounting_class}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.tax_code}
                                                    </Table.Cell>
                                                    <Table.Cell>
                                                        {i.is_active ? <Icon name="check" color='green' /> : <Icon name="times" />}
                                                    </Table.Cell>
                                                </Table.Row>)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                <ModalWrapper
                    showModal={this.state.formModal}
                    onModalClose={() => {
                        this.setState({
                            formModal: false,
                            recordIndex: -1
                        });
                    }}
                    recordIndex={this.state.recordIndex}
                    SubComponent={ItemInlineForm}
                    size='small'
                />
            </div>
        )
    }
}

BillingItemList.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchItemPaginatedList: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    setItemModel: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        list: state.billingItemList.list
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchItemPaginatedList,
        setItemModel
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(BillingItemList));