import update from 'immutability-helper';
import {
    SET_SHIPMENT_MODEL_FROM_SERVER,
    SHIPMENT_LINE_FULFILLMENT_SAVED,
    FULFILLMENT_LINE_DELETED,
    SHIPMENT_MODEL_PROPERTY_UPDATED,
    SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM,
    SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM,
    SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER,
    SHIPPO_PURCHASED_UNDER_SHIPMENT,
    NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT,
    ATTACHMENT_DELETED_FROM_SHIPMENT,
    SHIPPO_VOIDED,
    SHIPMENT_SHIPSTATION_STATUS_CHANGED,
    SHIPMENT_COMMENT_ADDED
} from '../../actions/types';

export default function shipmentModel(state = {
    id: null,
    shipping_addr: {},
    billing_addr: {},
    tracking: {},
    shipping: {},
    accounting: {},
    courier: {},
    shippo: [],
    containers: [],
    order: {},
    shipment_lines: [],
    comments: [],
    files: []
}, action = {}) {
    switch(action.type) {
        case SET_SHIPMENT_MODEL_FROM_SERVER:
            return action.payload;

        case SHIPMENT_LINE_FULFILLMENT_SAVED:
            const {appended, status_change} = action.payload.data;
            let status = state.status;
            if (status_change) {
                status = status_change;
            }
            return update(state, {
                status: {$set: status},
                shipment_lines: {
                    [action.payload.index]: {
                        fulfillments: {
                            $push: [appended]
                        }
                    }
                }
            })

        case FULFILLMENT_LINE_DELETED:
            return update(state, {
                status: {$set: 'In Warehouse'},
                shipment_lines: {
                    [action.payload.lineIndex]: {
                        fulfillments: {$splice: [[action.payload.fulfillmentIndex, 1]]}
                    }
                }
            })

        case SHIPMENT_MODEL_PROPERTY_UPDATED:
            const {field, value} = action.payload;
            if (field === 'waybill') {
                return update(state, {
                    tracking: {
                        waybill: {$set: value}
                    }
                })
            }
            if (field === 'cost') {
                return update(state, {
                    tracking: {
                        cost: {$set: value}
                    }
                })
            }
            if (field === 'carton_count') {
                return update(state, {
                    carton_count: {$set: value}
                })
            }
            if (field === 'status') {
                return update(state, {
                    status: {$set: value}
                })
            }
            if (field === 'packed') {
                return update(state, {
                    packed: {$set: false}
                })
            }
            if (field === 'tracking') {
                return update(state, {
                    tracking: {$set: value}
                })
            }
            if (field === 'billed') {
                return update(state, {
                    billed: {$set: value},
                    status: {$set: 'Finished'}
                })
            }
            if (field === 'exist_in_fba') {
                return update(state, {
                    exist_in_fba: {$set: 1}
                })
            }
            if (field === 'fba_feed_type') {
                return update(state, {
                    fba_feed_type: {$set: value}
                })
            }
            if (field.includes('attr')) {
                return update(state, {
                    [field]: {$set: value}
                })
            }

            return state;

        case SHIPMENT_LINE_QUANTITY_UPDATED_BY_FORM:
            return update(state, {
                shipment_lines: {
                    [action.payload.index]: {
                        updateQuantity: {$set: action.payload.quantity}
                    }
                }
            })

        case SHIPMENT_LINE_SHOULD_UPDATE_ORDER_LINE_UPDATED_BY_FORM:
            return update(state, {
                shipment_lines: {
                    [action.payload.index]: {
                        updateOrderLine: {$set: action.payload.value}
                    }
                }
            })

        case SHIPMENT_LINE_QUANTITY_UPDATED_ON_SERVER:
            return update(state, {
                status: {$set: action.payload.newStatus === 'Ready' ? 'Ready' : 'In Warehouse'},
                shipment_lines: {
                    [action.payload.index]: {
                        updateQuantity: {$set: null},
                        updateOrderLine: {$set: false},
                        quantity: {$set: action.payload.quantity}
                    }
                }
            })

        case SHIPPO_PURCHASED_UNDER_SHIPMENT:
            return update(state, {
                shippo: {$unshift: [action.payload]}
            })

        case SHIPPO_VOIDED:
            return update(state, {
                shippo: {
                    [action.payload.index]: {
                        $set: action.payload.data
                    }
                }
            })

        case NEW_ATTACHMENT_UPLOADED_TO_SHIPMENT: {
            return update(state, {
                files: {
                    $push: [action.payload]
                }
            })
        }

        case ATTACHMENT_DELETED_FROM_SHIPMENT: {
            return update(state, {
                files: {$splice: [[action.payload, 1]]}
            })
        }

        case SHIPMENT_SHIPSTATION_STATUS_CHANGED: {
            return update(state, {
                ss_order_id: {$set: action.payload}
            })
        }

        case SHIPMENT_COMMENT_ADDED: {
            return update(state, {
                comments: {
                    $push: [action.payload]
                }
            })
        }

        default: return state;
    }
}