import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import {withI18n} from 'react-i18next';
import PropTypes from 'prop-types';
import {Segment, Loader, Dimmer, Confirm, List} from 'semantic-ui-react';
import {
    fetchById,
    updateModelInStore,
    createNewModelInStore
} from '../../actions/integration';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import AlertComponent from '../includes/messages/alert';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import {api} from '../../api';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import WooHeader from './components/WooHeader';
import ShopifyHeader from './components/ShopifyHeader';
import CompanyShipStationHeader from './components/CompanyShipStationHeader';
import ThirdPartyShipStationHeader from './components/ThirdPartyShipStationHeader';
import TgHeader from './components/TgHeader';
import MagentoHeader from './components/MagentoHeader';
import FBAHeader from './components/FBAHeader';
import ZaloraHeader from './components/ZaloraHeader';
import TraceTable from '../includes/tables/TraceTable';
import OrderImportForm from './components/OrderImportForm';
import ShopifyFulfillmentCenterSelector from '../includes/selectors/integrations/ShopifyFulfillmentCenterSelector';
import TgFulfillmentLocationSelector from '../includes/selectors/integrations/TgFulfillmentLocationSelector';
import ShipStationStoreSelector from '../includes/selectors/integrations/ShipStationStoreSelector';
import PrimaryNaviButton from '../includes/buttons/PrimaryNaviButton';
import DeleteButton from '../includes/buttons/DeleteButton';

class ApiViewPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: '', active: true, key: 1}
            ],
            loading: false,
            deleteConfirmOpen: false,
            importReference: "",
            importingLoading: false
        }
    }

    componentDidMount() {
        const {location, match} = this.props;
        const {id} = match.params;
        this.setState({
            loading: true
        });
        this.props.fetchById(id).then(res => {
            this.setState({
                loading: false,
                breadcrub: this.getBreadCrub(location.backTo, res.name)
            })
        }).catch(err => {
            this.axiosError(err);
            this.setState({loading: false});
        });
    }

    componentWillUnmount() {
        this.props.createNewModelInStore();
    }

    axiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false,
                editModalOpen: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
    }

    getBreadCrub = (backTo, name) => {
        if (!backTo) {
            backTo = `/integrations/${LaraPagination.integrations(false)}`;
        }
        return [
            {isLink:true, to: backTo, tag: _.upperFirst('Integration'), active: false, key: 1},
            {isLink:false, to:null, tag: name, active: true, key: 2}
        ]
    }
    
    onDeleteConfirmClick = () => {
        this.setState({
            deleteConfirmOpen: true
        })
    }

    handleDeleteCancel = () => {
        this.setState({
            deleteConfirmOpen: false
        })
    }

    handleDeleteConfirm = () => {
        api.integrations.delete(this.props.model.id).then(r => {
            this.props.history.push(`/integrations/${LaraPagination.integrations(false)}`);
        })
    }

    onImportByReferenceClick = () => {
        const {importReference} = this.state;
        if (!importReference) {
            return null;
        }
        this.setState({
            importingLoading: true
        });
        api.integrations.importByRef(this.props.model.id, importReference).then(res => {
            this.setState({
                importingLoading: false,
                importReference: ""
            });
            Lara.alertSuccess("Order imported");
        }).catch(err => {
            this.axiosError(err);
            this.setState({
                importingLoading: false
            });
        })
    }

    updateModelOnServerClick = (action) => {
        const {model} = this.props;
        if (action === 'shopify-fulfillment') {
            api.integrations.updateShopifyFulfillmentCtr(model.id, model.key_5).then(res => {
                Lara.alertSuccess("Fulfillment Center Updated");
            })
        }
        if (action === 'tg-fulfillment') {
            api.integrations.updateTgfillmentCtr(model.id, model.key_2).then(res => {
                Lara.alertSuccess("Fulfillment Location Updated");
            })
        }
        if (action === 'ss-store-own-company') {
            api.integrations.updateSsStore(model.id, model.key_1).then(res => {
                Lara.alertSuccess("Store Updated");
            })
        }
        if (action === 'ss-store-third-party') {
            api.integrations.updateSsStore(model.id, model.key_4).then(res => {
                Lara.alertSuccess("Store Updated");
            })
        }
    }

    render() {
        const {t, model} = this.props;
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-8 col-sm-8 col-xs-12">
                        <div className="row">
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <Segment>
                                    <Dimmer inverted active={this.state.loading}>
                                        <Loader />
                                    </Dimmer>
                                    <SegmentRibbon label={model.name ? model.name : "Loading..."} Buttons={
                                        () => {return (<React.Fragment>
                                            {model.type !== '11' ? <PrimaryNaviButton
                                                link={`/integrations/${model.id}/action/edit`}
                                                size='mini'
                                                float='right'
                                                loading={false}
                                                label={t('Edit')}
                                            /> : null}
                                            <DeleteButton
                                                size='mini'
                                                float='right'
                                                label={t('Delete')}
                                                onClick={() => {
                                                    this.onDeleteConfirmClick()
                                                }}
                                                loading={false}
                                            />
                                        </React.Fragment>)}
                                    }/>
                                    <div className="row">
                                        <div className="col-md-12 col-sm-12 col-xs-12">
                                            {model.type === "4" ? <ShopifyHeader model={model}/> : null}
                                            {model.type === "5" ? <WooHeader model={model} /> : null}
                                            {model.type === "6" ? <TgHeader model={model} /> : null}
                                            {model.type === "8" ? <CompanyShipStationHeader model={model} /> : null}
                                            {model.type === "10" ? <MagentoHeader model={model}/> : null}
                                            {model.type === "14" ? <ThirdPartyShipStationHeader model={model} /> : null}
                                            {model.type === "15" ? <ZaloraHeader model={model} /> : null}
                                            {model.type === "11" ? <FBAHeader model={model} /> : null}
                                        </div>
                                    </div>
                                </Segment>
                            </div>
                        </div>

                        <div className="row lara-row-margin">
                            {model.id ? <div className="col-md-12 col-sm-12 col-xs-12">
                                <Segment>
                                    <SegmentRibbon label={t("Trace")} Buttons={null} />
                                    <TraceTable
                                        traceableId={model.id}
                                        traceableType='App\Models\ApiCredential'
                                    />
                                </Segment>
                            </div> : null}
                        </div>
                    </div>
                    {model.id && model.type !== "11"  ? <div className="col-md-4 col-sm-4 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={t("Action")} Buttons={null} />
                            <OrderImportForm
                                type={model.type}
                                reference={this.state.importReference}
                                onReferenceChange={(value) => {
                                    this.setState({importReference: value})
                                }}
                                onSubmit={() => {this.onImportByReferenceClick()}}
                                loading={this.state.importingLoading}
                                t={t}
                            />
                        </Segment>
                        {model.type === "4" ? <Segment>
                            <SegmentRibbon label={t("Mapping")} Buttons={null} />
                            <ShopifyFulfillmentCenterSelector
                                t={t}
                                onValueChange={(value) => {
                                    this.props.updateModelInStore('key_5', value)
                                }}
                                value={model.key_5}
                                label="Fulfillment ID"
                                apiId={model.id}
                                onUpdateClick={() => {
                                    this.updateModelOnServerClick("shopify-fulfillment")
                                }}
                            />
                        </Segment> : null}
                        {model.type === "6" ? <Segment>
                            <SegmentRibbon label={t("Mapping")} Buttons={null} />
                            <TgFulfillmentLocationSelector
                                t={t}
                                onValueChange={(value) => {
                                    this.props.updateModelInStore('key_2', value)
                                }}
                                value={model.key_2}
                                label="Ship From Location"
                                apiId={model.id}
                                onUpdateClick={() => {
                                    this.updateModelOnServerClick("tg-fulfillment")
                                }}
                            />
                        </Segment> : null}
                        {model.type === "8" ? <Segment>
                            <SegmentRibbon label={t("Mapping")} Buttons={null} />
                            <ShipStationStoreSelector
                                t={t}
                                onValueChange={(value) => {
                                    this.props.updateModelInStore('key_1', value)
                                }}
                                value={model.key_1}
                                label="Store"
                                apiId={model.id}
                                onUpdateClick={() => {
                                    this.updateModelOnServerClick("ss-store-own-company")
                                }}
                            />
                        </Segment> : null}
                        {model.type === "14" ? <Segment>
                            <SegmentRibbon label={t("Mapping")} Buttons={null} />
                            <ShipStationStoreSelector
                                t={t}
                                onValueChange={(value) => {
                                    this.props.updateModelInStore('key_4', value)
                                }}
                                value={model.key_4}
                                label="Store"
                                apiId={model.id}
                                onUpdateClick={() => {
                                    this.updateModelOnServerClick("ss-store-third-party")
                                }}
                            />
                        </Segment> : null}
                        <Segment>
                            <SegmentRibbon label={t("Options")} Buttons={null} />
                            <List divided relaxed>
                                <List.Item>
                                    {model.auto_process ? <List.Icon name='check' size='large' color='green' verticalAlign='middle' />:
                                        <List.Icon name='times' size='large' verticalAlign='middle' />}
                                    <List.Content>
                                        <List.Header>{t('AutoProcess')}</List.Header>
                                    </List.Content>
                                </List.Item>
                                <List.Item>
                                    {model.create_new_item ? <List.Icon name='check' size='large' color='green' verticalAlign='middle' />:
                                        <List.Icon name='times' size='large' verticalAlign='middle' />}
                                    <List.Content>
                                        <List.Header>{t('SaveNewInventory')}</List.Header>
                                    </List.Content>
                                </List.Item>
                            </List>
                        </Segment>
                    </div> : null}
                </div>

                <Confirm
                    open={this.state.deleteConfirmOpen}
                    content={t('PermanentlyDeleteApi')}
                    onCancel={this.handleDeleteCancel}
                    onConfirm={this.handleDeleteConfirm}
                />
            </div>
        )
    }
}

ApiViewPage.propTypes = {
    fetchById: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.shape({
            id: PropTypes.string.isRequired
    }),
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    updateModelInStore: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        model: state.apiModel,
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchById,
        updateModelInStore,
        createNewModelInStore
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(ApiViewPage));