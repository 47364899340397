import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {Link} from 'react-router-dom';
import PropTypes from 'prop-types';
import {withI18n} from 'react-i18next';
import Icon from '../../components/includes/svg/icon';
import {
    setSubMenuToShow
} from '../../actions/utils';
import ProfileCard from '../../components/includes/partials/ProfileCard';
import LaraPagination from '../../laraPagination';

class LaraSidebar extends Component {
    _isActive = (numOfChar, name, isRoot = false) => {
        const {location} = this.props.history;
        if (typeof location === 'object') {
            if (location.pathname === '/' && isRoot) {
                return "lara-active";
            }
            if ((location.pathname.substr(0, numOfChar) === name) && !isRoot) {
                return "lara-active";
            }
        }
        return null;
    }

    render() {
        const {t, config, setSubMenuToShow, preference, user} = this.props;
        return (
            <nav className="lara-sidebar">
                <div className="lara-sidebar__top">
                    <div className="lara-sidebar__logo">
                        {preference.logo ?
                            <img src={preference.logo} alt="Company Logo" className="lara-logo" />
                            : null}
                    </div>
                    <div className="lara-sidebar__nav-wrapper">
                        <ul className="lara-sidebar__nav">
                            <li className="lara-nav-item">
                                <Icon icon="Speed-Meter" className={"lara-nav-icon " + this._isActive(1,'/', true)}/>
                                <Link to='/'>
                                    <div className={"lara-nav-item-name " + this._isActive(1, '/', true)}>{t('Dashboard')}</div>
                                </Link>
                            </li>
                            <li
                                className="lara-nav-item lara-nav-header-wrapper lara-clickable"
                                onClick={() => {setSubMenuToShow('relation',config.openSubMenu)}}
                            >
                                <div className="lara-nav-header">{t('Relation')}</div>
                                <div>
                                    <Icon icon="Menu-1" className="lara-nav-icon lara-nav-header"/>
                                </div>
                            </li>
                            <li className={config.openSubMenu === 'relation' ? "lara-nav-item lara-sub-nav-item": "lara-hidden"}>
                                <ul>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="User-Flowchart" className={"lara-nav-icon " + this._isActive(19,'/relations/customer')}/>
                                        <Link to={`/relations/customer/${LaraPagination.relations(false, 'customer')}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(19,'/relations/customer')}>{t('Customer')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Connecting-Users" className={"lara-nav-icon " + this._isActive(17,'/relations/vendor')}/>
                                        <Link to={`/relations/vendor/${LaraPagination.relations(false, 'vendor')}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(17,'/relations/vendor')}>{t('Vendor')}</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {/*<li className="lara-nav-item">*/}
                            {/*    <Icon icon="Storage" className={"lara-nav-icon " + this._isActive(12,'/inventories')}/>*/}
                            {/*    <Link to={`/inventories/default/${LaraPagination.inventories()}`}>*/}
                            {/*        <div className={"lara-nav-item-name " + this._isActive(12,'/inventories')}>{t('Inventory')}</div>*/}
                            {/*    </Link>*/}
                            {/*</li>*/}
                            {/*//------Start: July 18, 2020 Update-----//*/}
                            <li className="lara-nav-item">
                                <Icon icon="Storage" className={"lara-nav-icon " + this._isActive(12,'/inventories')}/>
                                <Link to={`/inventories/default/${LaraPagination.inventories()}/list`}>
                                    <div className={"lara-nav-item-name " + this._isActive(12,'/inventories')}>{t('Inventory')}</div>
                                </Link>
                            </li>
                            {/*//------End: July 18, 2020 Update-----//*/}
                            <li
                                className="lara-nav-item lara-nav-header-wrapper lara-clickable"
                                onClick={() => {setSubMenuToShow('order',config.openSubMenu)}}
                            >
                                <div className="lara-nav-header">{t('Order')}</div>
                                <div>
                                    <Icon icon="Menu-1" className="lara-nav-icon lara-nav-header"/>
                                </div>
                            </li>
                            <li className={config.openSubMenu === 'order' ? "lara-nav-item lara-sub-nav-item": "lara-hidden"}>
                                <ul>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Registry-List" className={"lara-nav-icon " + this._isActive(16,'/customer-orders')}/>
                                        <Link to={`/orders/Customer-Order/default/${LaraPagination.basicOrders('Customer Order', false)}/basic`}>
                                            <div className={"lara-nav-item-name " + this._isActive(22,'/orders/Customer-Order')}>{t('Customer Order')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Shopping-list" className={"lara-nav-icon " + this._isActive(13,'/vendor-orders')}/>
                                        <Link to={`/orders/Vendor-Order/default/${LaraPagination.basicOrders('Vendor Order', false)}/basic`}>
                                            <div className={"lara-nav-item-name " + this._isActive(20,'/orders/Vendor-Order')}>{t('Vendor Order')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Renew-Card" className={"lara-nav-icon " + this._isActive(16,'/customer-returns')}/>
                                        <Link to={`/orders/Customer-Return/default/${LaraPagination.basicOrders('Customer Return', false)}/basic`}>
                                            <div className={"lara-nav-item-name " + this._isActive(23,'/orders/Customer-Return')}>{t('Customer Return')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Renew-Card" className={"lara-nav-icon " + this._isActive(14,'/vendor-returns')}/>
                                        <Link to={`/orders/Vendor-Return/default/${LaraPagination.basicOrders('Vendor Return', false)}/basic`}>
                                            <div className={"lara-nav-item-name " + this._isActive(21,'/orders/Vendor-Return')}>{t('Vendor Return')}</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                            {user.is_main_user ? <li
                                className="lara-nav-item lara-nav-header-wrapper lara-clickable"
                                onClick={() => {setSubMenuToShow('operation',config.openSubMenu)}}
                            >
                                <div className="lara-nav-header">{t('Operation')}</div>
                                <div>
                                    <Icon icon="Menu-1" className="lara-nav-icon lara-nav-header"/>
                                </div>
                            </li> : null}
                            {user.is_main_user ? <li className={config.openSubMenu === 'operation' ? "lara-nav-item lara-sub-nav-item": "lara-hidden"}>
                                <ul>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Container-Lifter" className={"lara-nav-icon " + this._isActive(9,'/licenses')}/>
                                        <Link to={`/licenses/${LaraPagination.licenses(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(9,'/licenses')}>{t('LicensePlate')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Fork-Lift" className={"lara-nav-icon " + this._isActive(10,'/f/inbound')}/>
                                        <Link to={`/f/inbound/default/${LaraPagination.shipments('inbound',false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(10,'/f/inbound')}>{t('Receiving')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Box-Forward" className={"lara-nav-icon " + this._isActive(11,'/f/outbound')}/>
                                        <Link to={`/f/outbound/default/${LaraPagination.shipments('outbound',false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(11,'/f/outbound')}>{t('Shipping')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Encode-File" className={"lara-nav-icon " + this._isActive(14,'/serials')}/>
                                        <Link to={`/serials/${LaraPagination.serials(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(8,'/serials')}>{t('SerialNumber')}</div>
                                        </Link>
                                    </li>
                                    {/* <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Clipboard" className={"lara-nav-icon " + this._isActive(16,'/bill-of-ladings')}/>
                                        <Link to=''>
                                            <div className={"lara-nav-item-name " + this._isActive(15,'/bill-of-lading')}>{t('Bill Of Lading')}</div>
                                        </Link>
                                    </li> */}
                                </ul>
                            </li> : null}
                            {user.is_main_user ? <li
                                className="lara-nav-item lara-nav-header-wrapper lara-clickable"
                                onClick={() => {setSubMenuToShow('billing',config.openSubMenu)}}
                            >
                                <div className="lara-nav-header">{t('Billing')}</div>
                                <div>
                                    <Icon icon="Menu-1" className="lara-nav-icon lara-nav-header"/>
                                </div>
                            </li> : null}
                            {user.is_main_user ? <li className={config.openSubMenu === 'billing' ? "lara-nav-item lara-sub-nav-item": "lara-hidden"}>
                                <ul>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Price-Tag-2" className={"lara-nav-icon " + this._isActive(14,'/billing/items')}/>
                                        <Link to={`/billing/items/${LaraPagination.billing.items(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(14,'/billing-items')}>{t('Item')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Contract-1" className={"lara-nav-icon " + this._isActive(19,'/billing/activities')}/>
                                        <Link to={`/billing/activities/${LaraPagination.billing.activities(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(19,'/billing-activities')}>{t('Activity')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Registry-1" className={"lara-nav-icon " + this._isActive(16,'/billing/entries')}/>
                                        <Link to={`/billing/entries/${LaraPagination.billing.entries(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(8,'/entries')}>{t('Entry')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Credit-Card" className={"lara-nav-icon " + this._isActive(16,'/billing/reports')}/>
                                        <Link to='/billing/reports'>
                                            <div className={"lara-nav-item-name " + this._isActive(16,'/billing/reports')}>{t('Report')}</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li> : null}
                            {user.is_main_user ? <li
                                className="lara-nav-item lara-nav-header-wrapper lara-clickable"
                                onClick={() => {setSubMenuToShow('setting',config.openSubMenu)}}
                            >
                                <div className="lara-nav-header">{t('Setting')}</div>
                                <div>
                                    <Icon icon="Menu-1" className="lara-nav-icon lara-nav-header"/>
                                </div>
                            </li> : null}
                            {user.is_main_user ? <li className={config.openSubMenu === 'setting' ? "lara-nav-item lara-sub-nav-item": "lara-hidden"}>
                                <ul>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Home" className={"lara-nav-icon " + this._isActive(8,'/company')}/>
                                        <Link to='/company'>
                                            <div className={"lara-nav-item-name " + this._isActive(8,'/company')}>{t('Company')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Networking-2" className={"lara-nav-icon " + this._isActive(8,'/clients')}/>
                                        <Link to={`/clients/${LaraPagination.clients(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(8,'/clients')}>{t('Client')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Construction-Plan" className={"lara-nav-icon " + this._isActive(8,'/locations')}/>
                                        <Link to={`/locations/${LaraPagination.Locations(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(10,'/locations')}>{t('Location')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Writing-Group" className={"lara-nav-icon " + this._isActive(6,'/users')}/>
                                        <Link to={`/users/${LaraPagination.users(false)}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(6,'/users')}>{t('User')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Preferences-1" className={"lara-nav-icon " + this._isActive(15,'/configurations')}/>
                                        <Link to='/configurations/couriers'>
                                            <div className={"lara-nav-item-name " + this._isActive(15,'/configurations')}>{t('Configuration')}</div>
                                        </Link>
                                    </li>
                                    <li className="lara-nav-item lara-nav-item-sub">
                                        <Icon icon="Collaboration" className={"lara-nav-icon " + this._isActive(13,'/integrations')}/>
                                        <Link to={`/integrations/${LaraPagination.integrations()}`}>
                                            <div className={"lara-nav-item-name " + this._isActive(13,'/integrations')}>{t('Integration')}</div>
                                        </Link>
                                    </li>
                                </ul>
                            </li> : null}
                            <li className="lara-nav-item">
                                <Icon icon="Bar-Chart" className={"lara-nav-icon " + this._isActive(8,'/reports', false)}/>
                                <Link to='/reports'>
                                    <div className={"lara-nav-item-name " + this._isActive(8, '/reports', false)}>{t('Report')}</div>
                                </Link>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="lara-sidebar__user">
                    <ProfileCard history={this.props.history} t={this.props.t}/>
                </div>
            </nav>
        )
    }
}

LaraSidebar.propTypes = {
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    config: PropTypes.object.isRequired,
    preference: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired,
    setSubMenuToShow: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
};

function mapStateToProps(state) {
    return {
        config: state.config,
        preference: state.system.preference,
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        setSubMenuToShow
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LaraSidebar));
