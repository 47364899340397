import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import {withI18n} from 'react-i18next';
import _ from 'lodash';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import {Form, Segment, Label, Table} from 'semantic-ui-react';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import {api} from '../../api';
import PrimaryButton from '../includes/buttons/PrimaryButton';

class InventoryAdjustmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'Inventory Adjustment History', active: true, key: 1}
            ],
            model: {
                from_date: "",
                to_date: ""
            },
            data: [],
            loading: false
        }
    }

    componentDidMount() {
        const {user} = this.props;

    }

    handleHttpErrors = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        this.setState({
            loading: false
        });
        Lara.axiosAlert(<AlertComponent
            support={errors.support}
            message={errors.message}
            reference={errors.reference}
            t={this.props.t}
        />);
    }

    onRetrieveDataClick = () => {
        this.setState({loading: true});
        const {from_date, to_date} = this.state.model
        api.inventories.adjustmentHistory(from_date, to_date).then(res => {
            this.setState({loading: false, data: res.data.data});
        }).catch(err => {
            this.handleHttpErrors(err);
        })
    }

    render() {
        const {t, user, clients} =this.props;
        const {model, items} = this.state;
        return (
            <div>
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`Adjustment History`} Buttons={null}/>
                            <div className="row">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Form size='mini'>
                                        <div className="row">
                                            <div className="col-md-3 col-sm-3 col-xs-12">
                                                <Form.Input
                                                    label='From Date'
                                                    type='date'
                                                    required={true}
                                                    value={model.from_date}
                                                    onChange={(e, {value}) => {
                                                        this.setState({
                                                            model: update(model, {
                                                                from_date: {$set: value}
                                                            })
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-12">
                                                <Form.Input
                                                    label='To Date'
                                                    type='date'
                                                    required={true}
                                                    value={model.to_date}
                                                    onChange={(e, {value}) => {
                                                        this.setState({
                                                            model: update(model, {
                                                                to_date: {$set: value}
                                                            })
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className="col-md-3 col-sm-3 col-xs-12">
                                                <div style={{'bottom': 4, 'position': 'absolute'}}>
                                                    <PrimaryButton
                                                         size='mini'
                                                        float='left'
                                                        label={t('Run')}
                                                        onClick={() => {
                                                            this.onRetrieveDataClick()
                                                        }}
                                                        loading={this.state.loading}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12 lara-row-margin">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    ID
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    SKU | UPC
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Reference
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Quantity
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Quarantined
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    Date (CET)
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>
                                        <Table.Body>
                                            {this.state.data.reverse().map(adj => {
                                                return (
                                                    <Table.Row key={adj.adjustment_id}>
                                                        <Table.Cell>{adj.adjustment_id}</Table.Cell>
                                                        <Table.Cell>{adj.sku} | {adj.upc}</Table.Cell>
                                                        <Table.Cell>{adj.reference}</Table.Cell>
                                                        <Table.Cell>{adj.change_in_quantity}</Table.Cell>
                                                        <Table.Cell>
                                                            {adj.quarantined_product ? <Label color='red' size='mini'>Y</Label> : <Label color='green' size='mini'>N</Label>}
                                                        </Table.Cell>
                                                        <Table.Cell>
                                                            {adj.adjusted_on}
                                                        </Table.Cell>
                                                    </Table.Row>
                                                )
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
            </div>
        )
    }
}

InventoryAdjustmentList.propTypes = {
    user: PropTypes.object.isRequired,
    t: PropTypes.func.isRequired
};

function mapStateToProps(state) {
    return {
        user: state.system.user
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({

    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(InventoryAdjustmentList))
