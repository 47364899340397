import React, { Component } from 'react';
import { bindActionCreators} from 'redux';
import { connect } from 'react-redux';
import _ from 'lodash';
import PropTypes from 'prop-types'
import {withI18n} from 'react-i18next';
import {Segment, Table, Dimmer, Loader, Modal, Header} from 'semantic-ui-react';
import update from 'immutability-helper';
import SegmentRibbon from '../includes/partials/SegmentRibbon';
import PageBreadCrub from '../includes/partials/PageBreadCrub';
import qs from 'qs';
import {
    fetchPaginatedList,
    licenseSaved,
    licenseDeleted,
    licenseMoved
} from '../../actions/license';
import OrderBy from '../includes/tables/OrderBy';
import SearchBar from '../includes/tables/SearchBar';
import Pagination from '../includes/tables/Pagination';
import AlertComponent from '../includes/messages/alert';
import Lara from '../../lara';
import LaraPagination from '../../laraPagination';
import LicenseTableRow from './components/LicenseTableRow';
import EditForm from './components/EditForm';
import BatchForm from './components/BatchForm';
import BreakForm from './components/BreakForm';
import PutawayForm from './components/PutawayForm';
import PrimaryButton from '../includes/buttons/PrimaryButton';
import SecondaryButton from "../includes/buttons/SecondaryButton";
import ExcelUpload from '../includes/excel/Main';

class LicenseListPage extends Component {
    constructor() {
        super();
        this.state = {
            breadcrub: [
                {isLink:false, to:null, tag: 'LicensePlate', active: true, key: 1}
            ],
            loading: false,
            routingQuery: {

            },
            routingPath: "",
            search: "",
            model: {

            },
            modelIndex: -1,
            editModal: false,
            batchModal: false,
            moveModal: false,
            breakModal: false,
            excelModalDisplay: false
        }
    }
    
    componentDidUpdate(prevProps, prevState) {
        const {params, url} = this.props.match;
        if (url !== this.state.routingPath) {
            this.setRoutingInState(params, url);
        }
    }

    componentDidMount() {
        const {params, url} = this.props.match;
        this.setRoutingInState(params,url);
    }

    setRoutingInState = ({query}, url) => {
        const q = qs.parse(query);
        //order_by
        //filter_by
        //query
        if (!_.isObject(q.filter_by)) {
            q.filter_by = {};
        }
        if (!_.isObject(q.order_by)) {
            q.order_by = {};
        }
        if (!_.isArray(q.query)) {
            q.query = [];
        }
        this.setState({
            loading: true,
            routingQuery: q,
            routingPath: url,
            search: q.search
        }, () => {
            this.fetchRecords(query)
        });
    }

    fetchRecords = () => {
        this.props.fetchPaginatedList(qs.stringify(this.state.routingQuery)).then(res => {
            this.setState({loading: false});
        }).catch(err => {
            this.axiosError(err);
        });
    }

    routeToNewQuery = (newRoutingQuery) => {
        this.props.history.push(`/licenses/${qs.stringify(newRoutingQuery)}`);
    }

    /*
     * For table header filters
    */
    onOrderByHeaderClicked = (routingOrderName, value) => {
        const newRoutingQuery = update(this.state.routingQuery, {
            order_by: {
                [routingOrderName]: {$set: value}
            }
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For pagination
    */
    onPaginationClicked = (e, data) => {
        const {activePage} = data;
        const newRoutingQuery = update(this.state.routingQuery, {
            on_page: {$set: activePage}
        });
        this.routeToNewQuery(newRoutingQuery);
    }

    /*
     * For Search bar: onSearchEnterKeyPressed onSearchChange onPredefinedRouteSelected (optional)
     */
    onSearchEnterKeyPressed = (e) => {
        if (e.key === 'Enter') {
            const defaultQuery = LaraPagination.licenses(true);
            defaultQuery.search = this.state.search;
            this.setState({
                queryId: 'default'
            }, () => {
                this.routeToNewQuery(defaultQuery);
            });
        }
    }

    //New Sep 12, 2019 - add pagination per page selector
    onPerPageChanged = (newPerPage) => {
        const query = update(this.state.routingQuery, {per_page: {$set: newPerPage}});
        this.routeToNewQuery(query);
    }

    onPredefinedRouteSelected = (value) => {
        return null;
    }

    axiosError = (err) => {
        const errors = Lara.axiosError(err, this.props.t);
        if (errors.http_code === 422) {
            this.setState({
                errors: errors.message,
                loading: false
            });
        } else {
            this.setState({
                loading: false
            });
            Lara.axiosAlert(<AlertComponent 
                support={errors.support}
                message={errors.message}
                reference={errors.reference}
                t={this.props.t}
            />);
        }
        this.setState({loading: false});
    }

    onEditClick = (license, index) => {
        if (license) {
            this.setState({
                model: license,
                modelIndex: index,
                editModal: true
            })
        } else {
            this.setState({
                model: Lara.models.license(),
                modelIndex: -1,
                editModal: true
            })
        }
    }

    onCloseModal = (modal) => {
        this.setState({
            model: {},
            modelIndex: -1,
            [modal]: false
        })
    }

    onModelSaved = (model) => {
        this.props.licenseSaved(this.state.modelIndex, model);
        this.setState({
            editModal: false,
            model: {},
            modelIndex: -1,
        });
    }

    onModelDelete = () => {
        this.props.licenseDeleted(this.state.modelIndex);
        this.setState({
            editModal: false,
            model: {},
            modelIndex: -1,
        });
    }

    onModelValueChange = (field, value) => {
        this.setState({
            model: update(this.state.model, {
                [field]: {$set: value}
            })
        })
    }

    batchCreateClicked = () => {
        this.setState({
            batchModal: true
        });
    }

    reloadAfterBatchOrBreak = (model) => {
        this.setState({
            [model]: false
        });
        this.fetchRecords();
    }

    onMoveClick = (license, index) => {
        this.setState({
            model:license,
            modelIndex: index,
            moveModal: true
        });
    }

    licenseMoved = (data) => {
        this.setState({
            moveModal: false
        });
        this.props.licenseMoved(this.state.modelIndex, data);
    }

    onBreakClick = (license) => {
        this.setState({
            model:license,
            breakModal: true
        });
    }

    excelPutAwayClicked = () => {
        this.setState({excelModalDisplay: true})
    }

    render() {
        const {routingQuery, search} = this.state;
        const {t, list, measure} = this.props;
        const {on_page, per_page, total} = list;
        const orderBy = _.isObject(routingQuery.order_by) ? routingQuery.order_by : {};
        let length = "CM";
        let weight = "KG";
        if (measure === "imperial") {
            length = "In";
            weight = "Lb";
        }
        return (
            <div>
                {/* Breadcrub Row */}
                <div className="row">
                    <div className="col-md-12 lara-breadcrub">
                        <PageBreadCrub lists={this.state.breadcrub} t={t}/>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 col-sm-12 col-xs-12">
                        <Segment>
                            <SegmentRibbon label={`${t('LicensePlate')} ${t('Search')}`} Buttons={
                                () => {return (<React.Fragment>
                                    <SecondaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Excel (Put-Away)')}
                                        onClick={() => {
                                            this.excelPutAwayClicked();
                                        }}
                                        loading={false}
                                    />
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('Create')}
                                        onClick={() => {
                                            this.onEditClick(null, -1);
                                        }}
                                        loading={false}
                                    />
                                    <PrimaryButton
                                        size='mini'
                                        float='right'
                                        label={t('BatchCreate')}
                                        onClick={() => {
                                            this.batchCreateClicked();
                                        }}
                                        loading={false}
                                    />
                                </React.Fragment>)}
                            }/>
                            <SearchBar
                                placeholder='Search by license...'
                                onSearchEnterKeyPressed={(e) => {this.onSearchEnterKeyPressed(e)}}
                                search={search}
                                onSearchChange={(search) => {this.setState({search})}}
                                onPredefinedRouteSelected={() => null}
                                options={[]}
                                havePreferenceSelect={false}
                                hasPerPageSelector={routingQuery.per_page}
                                onPerPageSelcted={(perPage) => {
                                    this.onPerPageChanged(perPage);
                                }}
                            />
                        </Segment>

                        <Segment>
                            <Dimmer inverted active={this.state.loading}>
                                <Loader />
                            </Dimmer>
                            <SegmentRibbon label={`${t('LicensePlate')} ${t('List')}`}/>
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Table
                                        compact
                                        basic
                                        padded={false}
                                    >
                                        <Table.Header>
                                            <Table.Row>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='LicensePlate'
                                                        routingOrderName='license'
                                                        currentOrder={orderBy.license}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                    />
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("Client")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("UOM")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("Dim")} ({t(length)})
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("Weight")} ({t(weight)})                                                    
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    {t("Location")}
                                                </Table.HeaderCell>
                                                <Table.HeaderCell>
                                                    <OrderBy
                                                        t={t}
                                                        name='CreatedAt'
                                                        routingOrderName='created_at'
                                                        currentOrder={orderBy.created_at}
                                                        onOrderClick={(routingOrderName, value) => {this.onOrderByHeaderClicked(routingOrderName, value)}}
                                                        uoms={this.props.uoms}
                                                        clients={this.props.clients}
                                                    />
                                                </Table.HeaderCell>
                                            </Table.Row>
                                        </Table.Header>

                                        <Table.Body>
                                            {list.records.map((item, index) => {
                                                return (<LicenseTableRow
                                                    key={item.id}
                                                    item={item}
                                                    onEdit={() => {
                                                        this.onEditClick(item, index);
                                                    }}
                                                    onMove={() => {
                                                        this.onMoveClick(item, index)
                                                    }}
                                                    onBreak={() => {
                                                        this.onBreakClick(item)
                                                    }}
                                                    uoms={this.props.uoms}
                                                    clients={this.props.clients}
                                                />)
                                            })}
                                        </Table.Body>
                                    </Table>
                                </div>
                            </div>
                        
                            <div className="row lara-row-margin">
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <Pagination 
                                        activePage={on_page}
                                        totalRecords={total}
                                        perPage={per_page}
                                        onPageChange={(e, data) => {
                                            this.onPaginationClicked(e,data);
                                        }}
                                    />
                                </div>
                            </div>
                        </Segment>
                    </div>
                </div>
                {/* editModal: false,
            batchModal: false,
            moveModal: false,
            breakModal: false */}
                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal('editModal')}}
                    closeOnEscape={true} open={this.state.editModal}>
                    <Header icon='pencil' content='' />
                    <Modal.Content>
                        <EditForm 
                            model={this.state.model}
                            onModelValueChange={(field,value) => {
                                this.onModelValueChange(field, value);
                            }}
                            clients={this.props.clients}
                            licenseSaved={(data) => {
                                this.onModelSaved(data);
                            }}
                            licenseDeleted={() => {
                                this.onModelDelete();
                            }}
                        />
                    </Modal.Content>
                </Modal>
                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal('batchModal')}}
                    closeOnEscape={true} open={this.state.batchModal}>
                    <Header icon='pencil' content={t('BatchCreate')} />
                    <Modal.Content>
                        <BatchForm
                            clients={this.props.clients}
                            batchCreated={() => {
                                this.reloadAfterBatchOrBreak('batchModal')
                            }}
                        />
                    </Modal.Content>
                </Modal>
                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal('moveModal')}}
                    closeOnEscape={true} open={this.state.moveModal}>
                    <Header icon='pencil'  content={t('Putaway')} />
                    <Modal.Content>
                        {this.state.model.id ? <PutawayForm
                            license={this.state.model}
                            licenseMoved={(data) => {
                                this.licenseMoved(data);
                            }}
                            t={t}
                        /> : null}
                    </Modal.Content>
                </Modal>
                <Modal size='mini' 
                    closeOnDimmerClick={false}
                    closeIcon
                    centered={false}
                    onClose={() => {this.onCloseModal('breakModal')}}
                    closeOnEscape={true} open={this.state.breakModal}>
                    <Header icon='pencil'  content={t('Break')} />
                    <Modal.Content>
                        {this.state.model.id ? <BreakForm
                            parentId={this.state.model.id}
                            batchCreated={() => {
                                this.reloadAfterBatchOrBreak('breakModal')
                            }}
                        /> : null}
                    </Modal.Content>
                </Modal>
                <ExcelUpload
                    displayModal={this.state.excelModalDisplay}
                    onCloseModalClick={() => {
                        this.setState({excelModalDisplay: false})
                    }}
                    onUploaded={() => {
                        this.fetchRecords()
                    }}
                    type='PutAway'
                />
            </div>
        )
    }
}

LicenseListPage.propTypes = {
    t: PropTypes.func.isRequired,
    history: PropTypes.shape({
       push: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.object.isRequired,
    match: PropTypes.shape({
        params: PropTypes.object.isRequired,
        path: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    fetchPaginatedList: PropTypes.func.isRequired,
    list: PropTypes.object.isRequired,
    measure: PropTypes.string.isRequired,
    uoms: PropTypes.array.isRequired,
    licenseSaved: PropTypes.func.isRequired,
    licenseDeleted: PropTypes.func.isRequired,
    licenseMoved: PropTypes.func.isRequired,
};

function mapStateToProps(state) {
    return {
        list: state.licenses.list,
        measure: state.system.preference.measure,
        uoms: state.system.resources.uoms,
        clients: state.system.clients
    }
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators({
        fetchPaginatedList,
        licenseSaved,
        licenseDeleted,
        licenseMoved
    }, dispatch)
}

export default withI18n()(connect(mapStateToProps, mapDispatchToProps)(LicenseListPage));